import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Regex } from "../enum/Regex.enum";

/**
 * Validación de una fecha insertada de forma manual
 * o mediante datepicker
 * @param fecha
 * @returns
 */
export function validarFecha(fecha: any, requerido: boolean): boolean {
  let fechaValida = false;
  if (fecha instanceof Date) {
    fechaValida = true;
  } else if (typeof fecha === 'string') {
    fechaValida = validarFechaString(fecha);
  } else if (fecha?._d !== null && fecha?._d !== undefined) {
    fechaValida = validarFechaObjeto(fecha);
  }
  if (!requerido) {
    fechaValida = fechaValida || fecha == null || fecha == undefined || fecha == '';
  }
  return fechaValida;
}

const esFechaValida = (date: Date, anyo: number, mes: number, dia: number): boolean => {
  return date.getFullYear() === anyo && date.getMonth() === (mes - 1) && date.getDate() === dia;
};

const validarFechaString = (fecha: string): boolean => {
  const regex = new RegExp(Regex.fecha);
  if (regex.test(fecha)) {
    const [anyo, mes, dia] = fecha.split('-').map(Number);
    const date = new Date(anyo, mes - 1, dia);
    return esFechaValida(date, anyo, mes, dia);
  }
  return false;
};

const validarFechaObjeto = (fecha: any): boolean => {
  const validarDiaMes = (i: number) => i.toString().length >= 1 && i.toString().length <= 2;
  const dia = fecha._d.getDay();
  const mes = fecha._d.getMonth();
  const anyo = fecha._d.getFullYear();
  return validarDiaMes(dia) && validarDiaMes(mes) && anyo.toString().length === 4;
};

export function fechaValidator(requerido: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let result: any = null;
    if (!validarFecha(control.value, requerido)) {
      result = { fechaInvalida: true };
    }
    return result;
  };
}

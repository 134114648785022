import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FailedRules } from '../../models/errors/failedRules';

@Injectable({
  providedIn: 'root'
})
export class ErrorReglasService {
/**
 * Se emite un valor de tipo FailedRules en caso de
 * que se produzca un error en la validación de reglas
 */
  public errorReglaSubject:BehaviorSubject<FailedRules|null> = new BehaviorSubject<FailedRules|null>(null);
  /**
   * Método constructor
   */
  constructor() { }

   /**
    * Método para establecer el valor de errorReglaSubject
    */
   setErrorRegla(value: FailedRules): void {
    this.errorReglaSubject.next(value);
  }

  /**
   * Método para restablecer el valor de errorReglaSubject a null
   */
  resetErrorRegla(): void {
    this.errorReglaSubject.next(null);
  }

}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ArqConfig } from './core/models/arqConfig';
import { HeaderInterceptor } from './core/interceptors/header.interceptor';
import { SharedModule } from './shared/shared.module';
import { SessionService } from './core/providers/session/session.service';
import { UtilsService } from './core/providers/utils/utils.service';
import { ResponsiveService } from './core/providers/utils/responsive.service';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { DatePipe } from '@angular/common';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { ErrorReglaInterceptor } from './core/interceptors/error-regla.interceptor';

@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ],
  providers: [
    DatePipe

  ]
})
export class FrontendModule {
  /**
   * Método forRoot para importar la librería y pasar
   * la configuracion por parametr
   * Los valores de los parámetros se obtienen del fichero Config.json de la aplicacion
   * @param apiUrl url de la api
   * @param env entorno
   * @returns ModuleWithProviders<frontendModule>
  */
  static forRoot(config: ArqConfig): ModuleWithProviders<FrontendModule> {
    return {
      ngModule: FrontendModule,
      providers: [
        { provide: 'LIB_CONFIG', useValue: config },
        SessionService,
        UtilsService,
        ResponsiveService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CacheInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorReglaInterceptor,
          multi: true
        }
      ]
    };
  }
}

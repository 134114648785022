import { Injectable } from '@angular/core';
import { Fichero } from '../../enum/Fichero.enum';
import { FicheroHex, FicherosHEX } from '../../enum/ficherosHEX';
import compress from 'compress-base64';
import { CompressB64 } from '../../models/compressB64';
import { Documento } from '../../models/documentos/Documento';
import { Buffer } from 'buffer';
@Injectable({
  providedIn: 'root'
})
export class FicheroService {

  /**
   * Método que se utiliza para
   * validar si el fichero adjunto
   * está permitido.
   * @param file documento adjunto
   * @param extensionesPermitidas array con los ficheros permitidos
   * @returns Promise<boolean>
   */
  public async checkExtension(file: File, extensionesPermitidas: Fichero[]) {
    const [magicByte] = await Promise.all([this.getMagicNumber(file, 4)]);
    /**
     * Se busca el elemento que coincida por tipo y valor (magic number)
     */
    const extensionEncontrada = extensionesPermitidas.filter((tipoFichero: Fichero) => {
      return FicherosHEX.find((hex: FicheroHex) => tipoFichero === hex.type && hex.value === magicByte)
    });
    return extensionEncontrada.length > 0;
  }
  /**
   * Método que devuelve el magic number o magic byte
   * del fichero que se ha adjuntado
   * @param file documento
   * @param signatureLength posicion final para slice
   * @returns Promise<string>
   */
  public async getMagicNumber(file: File, signatureLength: number): Promise<string> {
    return file
      .slice(0, signatureLength)
      .arrayBuffer()
      .then((buffer) =>
        Array.from(new Uint8Array(buffer))
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('')
          .toUpperCase()
      );
  }

  /**
   * Método que valida si el formato
   * del nombre del fichero adjunto es válido
   * @param documento documento
   * @returns boolean
   */
  public hasCaracteresInvalidos(documento: any): boolean {
    const fileName = documento.target.files[0].name;
    const illegalRe = /[\/\?<>\\:;+`=%\*\|"$:]/g; //Illegal Characters on Various Operating System / ? < > \ : ; + ` = % * | " $
    const controlRe = /[\x00-\x1f\x80-\x9f]/g; // Unicode Control codes C0 0x00-0x1f & C1 (0x80-0x9f)
    const reservedRe = /^\.+$/; //Reserved filenames on Unix-based systems (".", "..")
    const urlcontrol = /^(http|https)/;
    const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;//Reserved filenames in Windows ("CON", "PRN", "AUX", "NUL", "COM1"...
    const pattern = new RegExp(illegalRe.source + "|" + controlRe.source + "|" + reservedRe.source + "|" + urlcontrol.source + "|" + windowsReservedRe.source);
    return fileName?.match(pattern);
  }
  /**
   * Método que comprueba que la
   * longitud del nombre del fichero
   * no supere el límite
   * @param documento documento
   * @param maxLength numero maximo de caracteres
   * @returns boolean - true su excede el máximo
   */
  public checkMaxFileNameLength(documento: any, maxLength: number): boolean {
    const fileNamesize = documento.target.files[0].name?.length || 0;
    return documento && fileNamesize > maxLength;
  }


  /**
   * Método que comprueba que el fichero
   * enviado/recibido no supere
   * el tamaño establecido
   * Devuelve true si el tamaño está permitido
   */
  public hasTamanyoPermitido(documento: any, sizeMax: number): boolean {
    const currentSize = documento.target.files[0].size;
    return sizeMax >= currentSize;
  }

  /**
   * Método que comprime las imagenes
   * @param file
   * @param options
   */
  public async createBase64(file: File, options: CompressB64): Promise<string> {
    return new Promise((resolve) => {
      const type = file.type
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        let base64String = reader.result as string;
        if (type.includes('image') && file.size > 2097152) {
          await compress(reader.result as string, options).then(result => {
            base64String = result as string;
          });
        }
        resolve(base64String);
      };
    });
  }
  /**
   * Transforma un fichero en base 64 a binario
   * y genera una url a partir del blob para poder visualizar el documento
   * @param fichero
   * @returns
   */
  private getFileUrlFromBase64(fichero: Documento) {
    const binaryData = Buffer.from(fichero.base64, 'base64'); // Decodifica Base64 a binario
    const blob = new Blob([binaryData], { type: fichero.mimeType ? fichero.mimeType : 'application/pdf' }); // Crea un Blob a partir del binario
    const url = URL.createObjectURL(blob);
    return url;
  }
  /**
 * Se abre una nueva pestaña en el navegador
 * donde se visualiza el fichero
 * @param fichero
 */
  public visualizarFicheroNewTab(fichero: Documento) {
    const url = this.getFileUrlFromBase64(fichero);
    window.open(url);
    setTimeout(() => URL.revokeObjectURL(url), 60000);
  }
  /**
   * Se descarga el documento y se abre con
   * la app definida por defecto para esa extensión
   * @param fichero
   */
  public descargarVisualizarFicheroPc(fichero: Documento) {
    const url = this.getFileUrlFromBase64(fichero);
    const link = document.createElement('a');
    link.href = url;
    link.download = fichero.nombre;
    link.click();
  }

    /**
   * Método para calcular el tamaño
   * en bytes sobre un string base64
   * @param base64String tipo string
   * @returns number con los bytes
   */
  public calculateFileSize(base64String: string): number {
      const base64Data = base64String.replace(/^data:[^;]*;base64,/, '');
      const binaryData = atob(base64Data);
      const fileSizeInBytes = binaryData.length;
      return fileSizeInBytes;
    }
}

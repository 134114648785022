import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '../../../core/providers/modal.service';
import { UserServiceService } from '../../../core/providers/user-service.service';
import { CaraDocumentoComponent, SessionService, AsistenteOcrService, ValidacionOcrService } from '@mutual-lib/frontend';

@Component({
  selector: 'app-modal-asistente',
  templateUrl: './modal-asistente.component.html',
  styleUrls: ['./modal-asistente.component.scss']
})
export class ModalAsistenteComponent implements OnInit {

  constructor(
    public asistenteOcrSrv: AsistenteOcrService,
    public userSrv: UserServiceService,
    public modalSrv: ModalService,
    public dialogRef: MatDialogRef<ModalAsistenteComponent>,
    public sessionSrv: SessionService,
    public validacionOcrSrv: ValidacionOcrService
  ) { }

  ngOnInit(): void {
    this.asistenteOcrSrv.styleCDK();
  }

  /**
   * Método para resetear
   * la digitalización
   * del DNI
   */
  resetearDigitalizacion() {
    this.asistenteOcrSrv.showInputsOCR = false;
    this.validacionOcrSrv.estadoDocumentos = [];
    this.closeDialog();
    this.modalSrv.mostrarAsistente(CaraDocumentoComponent);
  }

  /**
   * Método para cerrar
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
  }
}

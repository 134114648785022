import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { SessionService } from '../session/session.service';
import { LevelType } from '../../enum/levelType.enum';
import { LogService } from '../logs/log.service';
import { Aplicacion } from '../../models/perfil';
import { ErrorService } from '../errors/error.service';
import { UtilsService } from '../utils/utils.service';
import { Menu } from '../../models/menus/menu';
import { PermisoFuncional } from '../../models/PermisoFuncional';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /**
   * Nombre api
   */
  apiName!: string;
  /**
   * Identificador de la aplicacion actual
   */
  identificadorApp!: string;
  /**
   * Lista de permisos funcionales
   * que tendrá el usuario
   */
  public permisosFuncionales!: PermisoFuncional[];
  /**
   * Lista de menús accesibles
   * que tendrá el usuario
   */
  public menus!: Menu[];

  /**
   * Lista de elementos que tendrá accesible
   * el usuario en la ventana en la que se encuentre
   */
  public elementosVentana!: string[];

  /**
   * Lista de permisos funcionales
   * que tendrá el usuario
   */
  public permisosInicioKeycloak!: boolean;

  /**
   * Lista de rutas permitidas para
   * la aplicación
   */
  public rutas!: string[] | undefined;

  /**Clases y servicios */
  private http!: HttpClient;
  private sessionSrv!: SessionService;
  private logSrv!: LogService;
  private errorSrv!: ErrorService;
  private utilsSrv!: UtilsService;

  /**
   * Método constructor
   * @param http http client clase de angular
   */
  constructor() {
    this.apiName = '/core-autorizacion/perfil/aplicaciones';
    this.http = inject(HttpClient);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.errorSrv = inject(ErrorService);
    this.utilsSrv = inject(UtilsService);
    this.identificadorApp = this.sessionSrv.getLibConfig().identificadorApp;
  }

  /**
   * Método que nos devuelve todas las aplicaciones
   * de un usuario en función de sus roles asignados.
   * El backend nos devolverá cada aplicacion mergeando
   * los distintos roles
   * @returns Observable<Aplicacion[]>
   */
  public getAplicaciones(traceId: string): Observable<Aplicacion[]> {
    this.logSrv.insertLog(
      traceId,
      'getAplicaciones',
      'call',
      LevelType.info,
      'Inicio obtención de todas las aplicaciones',
      this.sessionSrv.getCurrentUserMask()
    );
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<Aplicacion[]>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName,
      { headers }
    );
  }
  /**
   * Método que nos devuelve una aplicación especifica
   * de un usuario en función de sus roles asignados.
   * El backend hará merge de los distintos roles.
   * @returns Observable<Aplicacion[]>
   */
  public getAplicacionByName(traceId: string): Observable<Aplicacion> {
    this.logSrv.insertLog(
      traceId,
      'getAplicacionByName',
      'call',
      LevelType.info,
      'Inicio obtención detalle aplicación por nombre',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp;
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<Aplicacion>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName + endpoint,
      { headers }
    );
  }
  /**
   * Obtencion de menús en base al
   * nombre de la aplicacion y usuario
   * @returns Observable<Menu[]>
   */
  public getMenus(traceId: string): Observable<Menu[]> {
    this.logSrv.insertLog(
      traceId,
      'getMenus',
      'call',
      LevelType.info,
      'Inicio obtención menús',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp + '/menus';
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<Menu[]>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName + endpoint,
      { headers }
    );
  }
  /**
   * Obtencion de rutas en base al
   * nombre de la aplicacion y usuario
   * @returns Observable<string[]>
   */
  public getRutas(traceId: string): Observable<string[]> {
    this.logSrv.insertLog(
      traceId,
      'getRutas',
      'call',
      LevelType.info,
      'Inicio obtención rutas',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp + '/rutas';
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<string[]>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName + endpoint,
      { headers }
    );
  }
  /**
   * Obtencion de permisos funcionales en base al
   * nombre de la aplicacion y ususario
   * @returns Observable<string[]>
   */
  public getPermisosFuncionales(traceId: string): Observable<PermisoFuncional[]> {
    this.logSrv.insertLog(
      traceId,
      'getPermisosFuncionales',
      'call',
      LevelType.info,
      'Inicio obtención permisos funcionales',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp + '/permisos-funcionales';
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<PermisoFuncional[]>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName + endpoint,
      { headers }
    );
  }
  /**
   * Recorre el array de permisos funcionales y busca el nombre del permiso
   * Si lo encuentra devuelve el idPermiso
   * @param nombrePermiso columna permiso de bbdd
   * @returns string
   */
  public getPermisoIdFromPermiso(nombrePermiso: string): string {
    let id: string = '';
    if (this.utilsSrv.validarLengthArray(this.permisosFuncionales)) {
      const pf: PermisoFuncional | undefined = this.permisosFuncionales.find((permisoF: PermisoFuncional) => this.utilsSrv.compararValores(permisoF.permiso, nombrePermiso));
      id = this.utilsSrv.existeElemento(pf) ? pf!.permisoId : '';
    }
    return id;
  }
  /**
  * Recorre el array de permisos funcionales y busca el nombre del permiso
  * Si lo encuentra devuelve el permiso
  * @param nombrePermiso columna permiso de bbdd
  * @returns PermisoFuncional | undefined;
  */
  public getPermisoFuncionalByPermiso(nombrePermiso: string) {
    let pfu: PermisoFuncional | undefined;
    if (this.utilsSrv.validarLengthArray(this.permisosFuncionales)) {
      pfu = this.permisosFuncionales.find((permisoF: PermisoFuncional) => this.utilsSrv.compararValores(permisoF.permiso, nombrePermiso));
    }
    return pfu;
  }
  /**
   * Obtencion de elementos que se mostrarán
   * en base a la ventana desde la que se llame
   * @param traceId
   * @param nameVentana
   * @returns Observable<string[]>
   */
  public getVentanas(traceId: string): Observable<string[]> {
    this.logSrv.insertLog(
      traceId,
      'getVentanas',
      'call',
      LevelType.info,
      'Inicio obtención ventanas',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp + '/ventanas';
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<string[]>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName + endpoint,
      { headers }
    );
  }
  /**
   * Obtencion de elementos que se mostrarán
   * en base a la ventana desde la que se llame
   * @param traceId
   * @param nameVentana
   * @returns Observable<string[]>
   */
  public getVentanaByName(traceId: string, nameVentana: string): Observable<string[]> {
    this.logSrv.insertLog(
      traceId,
      'getPerfilado',
      'call',
      LevelType.info,
      'Inicio obtención ventana por nombre',
      this.sessionSrv.getCurrentUserMask()
    );
    const endpoint = '/' + this.identificadorApp + '/ventanas/';
    const headers = new HttpHeaders({
      traceid: traceId,
    });
    return this.http.get<string[]>(
      this.sessionSrv.config.apiConf.apiUrl +
      this.apiName +
      endpoint +
      nameVentana,
      { headers }
    );
  }
  /**
   * Carga los menus de la aplicacion
   * @param traceId identificador de la traza
   */
  public async loadMenus(traceId: string) {
    this.menus = await lastValueFrom(this.getMenus(traceId));
    this.checkArrayElementos(this.menus);
    this.logSrv.insertLog(
      traceId,
      'loadMenus',
      'navigation',
      LevelType.info,
      'Menús cargados correctamente',
      this.sessionSrv.getCurrentUserMask()
    );
  }

  /**
   * Carga las rutas de la aplicacion
   * @param traceId identificador de la traza
   */
  public async loadRutas(traceId: string) {
    this.rutas = await lastValueFrom(this.getRutas(traceId));
    this.logSrv.insertLog(
      traceId,
      'loadRutas',
      'navigation',
      LevelType.info,
      'Rutas cargadas correctamente',
      this.sessionSrv.getCurrentUserMask()
    );
  }
  /**
   * Carga los permisos funcionales de la aplicacion
   * @param traceId identificador de la traza
   */
  public async loadPermisosFuncionales(traceId: string) {
    this.permisosFuncionales = await lastValueFrom(
      this.getPermisosFuncionales(traceId)
    );
    this.logSrv.insertLog(
      traceId,
      'loadPermisosFuncionales',
      'navigation',
      LevelType.info,
      'Permisos funcionales cargados correctamente',
      this.sessionSrv.getCurrentUserMask()
    );
  }

  /**
   *  Carga los elementos de la ventana y
   * en caso de que el array llegue vacio
   * lanza modal de error
   * @param traceId
   * @param nameVentana
   */
  public async loadElementosVentana(traceId: string, nameVentana: string) {
    this.sessionSrv.showSpinner = true;
    this.elementosVentana = await lastValueFrom(
      this.getVentanaByName(traceId, nameVentana)
    );
    this.checkArrayElementos(this.elementosVentana);
    this.logSrv.insertLog(
      traceId,
      'getPerfilado',
      'navigation',
      LevelType.info,
      'Elementos de la ventana cargados correctamente',
      this.sessionSrv.getCurrentUserMask()
    );
    this.sessionSrv.showSpinner = false;
  }
  /**
   * Método que valida
   * si han llegado elementos de menú o
   * de la ventana y en caso contrario
   * lanzar modal de error
   * @param array
   */
  public checkArrayElementos(array: any[]) {
    if (!this.utilsSrv.validarLengthArray(array)) {
      this.errorSrv.showMsgErrorPerfilado();
      //Si es la carga inicial de la aplicación y los menús están vacíos fuerza que no se muestren los elementos la ventana
      this.permisosInicioKeycloak = false;
      this.sessionSrv.showSpinner = false;
    } else {
      this.permisosInicioKeycloak = true;
    }
  }

  /**
   * Devuelve true o false si la ruta pasada por parámetro
   * está incluida en el array de rutas recuperadas
   * del arbol de perfilado
   * @param rutaActual path de la ruta actual
   */
  public isRutaPermitida(rutaActual: string) {
    /**Eliminamos parámetros añadidos */
    const hashIndex = rutaActual.indexOf('#');
    if (!this.utilsSrv.compararValores(hashIndex, -1)) {
      rutaActual = rutaActual.split('#')[0]
    }
    const rutaSinIdioma = rutaActual.slice(4);
    const find = this.rutas?.find(
      (elem: string) => (rutaSinIdioma.length > elem.length) ?
        rutaSinIdioma.startsWith(elem + '/') :
        rutaSinIdioma.startsWith(elem)
    );
    return this.utilsSrv.existeElemento(find);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { DatepickerHeaderComponent } from '../datepicker-header/datepicker-header.component';


@Component({
  selector: 'mm-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective }
  ]
})
export class InputComponent {
  /**
   * identificador del elemento
   * para el perfilado
   * */
  @Input() id!: string | undefined;
  /**
   * Etiqueta del campo
   */
  @Input() label!: string;
  /**
   * Tipo de input: text,
   * number,
   * calendar,
   * select
   */
  @Input() type!: string;
  /**
   * Nombre del form control name
   */
  @Input() name!: string;
  /**
   * Texto a mostrar en el placeholder
   * como información para el usuario
  */
  @Input() infoPlaceholder!: string;
  /**
   * Mostrar u ocultar
  */
  @Input() show: boolean = true;
  /**
   * Habilitar o bloquear
  */
  @Input() disabledInput!: boolean;
  /**
   * Campo obligatorio
  */
  @Input() requiredInput!: boolean;
  /**
   * Muestra u oculta etiqueta optional
   * en campos no requeridos
   */
  @Input() showOptional: boolean = true;

  /**CONFIG SELECT */
  /**
   * Nombre del form control name
   */
  @Input() options!: any[];
  /**
   * Nombre del campo para el value de la opción
   */
  @Input() valueOption!: string;
  /**
   * Nombre del campo para mostrar el label
   */
  @Input() labelOption!: string;
  /**Config adicional datepicker */
  /**Cabecera custom del calendario */
  customDatePickerHeader: any = DatepickerHeaderComponent;
  /**
   * Nombre del campo para mostrar el label
   */
  @Input() filterCalendar!: any;

  @Output() notifySelectChange: EventEmitter<any> = new EventEmitter();
  /**
   * Variable para controlar los errores
   * personalizados de los inputs
   */
  @Input() regex: string = "";

  calendarOpen: boolean = false;
  selectOpen: boolean = false;



  /**
   * Método constructor
   * @param controlContainer para comunicarse con el formGroup del padre
   */
  constructor(public controlContainer: ControlContainer) { }
  ngOnInit() {
    if (this.disabledInput) {
      this.form.controls[this.name]?.disable();
    }
  }
  /**
   * Permite acceder al control
   * para hacer validaciones
   */
  get form() {
    return this.controlContainer.control as FormGroup;
  }
  /**
   * Notifica al componente padre
   * el elemento seleccionado de la lista
   * desplegable
   * @param option opción seleccionada
   */
  selectionChange(option: any) {
    this.notifySelectChange.emit(option);
  }
  clearValue() {
    this.form.controls[this.name]?.setValue(null)
  }
}

import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { LevelType } from '../../../core/enum/levelType.enum';
import { FicheroService } from '../../../core/providers/files/fichero.service';
import { LogService } from '../../../core/providers/logs/log.service';
import { SessionService } from '../../../core/providers/session/session.service';
import { UtilsService } from '../../../core/providers/utils/utils.service';
import { ErrorFormComponent } from '../../../shared/modals/error-form/error-form.component';
import { Fichero } from '../../enum/Fichero.enum';
import { EstadoDocumento } from '../../models/estadoDocumento';
import { dniValidador, nieValidator } from '../../validators/validatorNifNie';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class ValidacionOcrService {

  /**
   * Variables para los
   * servicios
   */
  private utilsSrv!: UtilsService;
  private ficheroSrv!: FicheroService;
  private modalSrv!: ModalService;
  private sessionSrv!: SessionService;
  private translateSrv!: TranslateService;
  public logSrv: LogService;

  /**
   * Variable para lista de estadoDocumentos
   */
  public estadoDocumentos: EstadoDocumento[] = [];
  /**
   * Variable para lista de estadoSolicitud
   */
  public estadoSolicitud: EstadoDocumento[] = [];
  /**
   * Variable para validación de letras
   */
  public validarLetras: string = 'TRWAGMYFPDXBNJZSQVHLCKE';
  /**
   * Array donde estan los tipos de ficheros
   * aceptados
   */
  public arrayExtensiones: Array<Fichero> = [Fichero.PDF, Fichero.JPG, Fichero.JPEG, Fichero.PNG];

  private altIconoCerrar = 'modal.errorForm.altIconoCerrar';

  private errorFormTitle = 'modal.errorForm.title';
  /**
  * Método constructor
  * @param inject tipo inject
  * para inyectar dependencias
  */
  constructor() {
    this.utilsSrv = inject(UtilsService);
    this.ficheroSrv = inject(FicheroService);
    this.modalSrv = inject(ModalService);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.translateSrv = inject(TranslateService);
  }

  /**
   * Método para validar si los documentos
   * anexados son la combinación correcta
   */
  validarTiposDocumento(documentos: EstadoDocumento[]) {
    const tipoDocumento = documentos[0].tipoDocumento;
    let combinacionValida;
    switch (tipoDocumento) {
      case '37': // dni anverso + reverso
      case '254': // permiso de residencia
        combinacionValida = this.utilsSrv.compararValores(documentos.length, 1);
        break;
      case '35': // dni anverso --> dni reverso
        combinacionValida = this.combinacionValida([2], documentos.length, ['36'], documentos);
        break;
      case '36': // dni reverso --> dni anverso
        combinacionValida = this.combinacionValida([2], documentos.length, ['35'], documentos);
        break;
      case '248': // certificado nie --> pasaporte / id extranjero
        combinacionValida = this.combinacionValida([2], documentos.length, ['38', '249'], documentos);
        break;
      case '38': // pasaporte --> certificado nie / visado
        combinacionValida = this.utilsSrv.compararValores(documentos.length, 1) || this.combinacionValida([2], documentos.length, ['248', '259'], documentos);
        break;
      case '249': // id extranjero ->  certificado nie / visado
        combinacionValida = this.combinacionValida([2], documentos.length, ['248', '259'], documentos);
        break;
      case '259': // visado --> id extranjero / pasaporte
        combinacionValida = this.combinacionValida([2], documentos.length, ['249', '38'], documentos);
        break;
      default:
        combinacionValida = false;
        break;
    }
    return combinacionValida;
  }

 /**
  * posiblesLength [1] [2] [1,2] representa el tamaño que puede tener
  * el array de documentos,
  * lengthActual tamaño real del array de documentos.
  * posiblesTiposDocumentos ['259', '248'] posibles tipos de los documentos para la combinación
  */
  combinacionValida(posiblesLength: number[], lengthActual: number, posiblesTiposDocumentos: string[], documentos: EstadoDocumento[]) {
    return this.utilsSrv.existeElemento(documentos[1]) && posiblesLength.includes(lengthActual) && posiblesTiposDocumentos.includes(documentos[1].tipoDocumento);
  }

  /**
  * Metodo que valida que el nombre del documento
  * no supere los 128 caracteres.
  */
  public checkMaxfileName(traceId: string, documento: any, modalTrad: any) {
    let success = true;
    if (this.ficheroSrv.checkMaxFileNameLength(documento, 128)) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.error, 'Error en longitud nombre documento', this.sessionSrv.getCurrentUserMask());
      this.modalSrv.mostrarModal(ErrorFormComponent, {
        titulo: this.translateSrv.instant('modal.errorFormDocNameSize.title'),
        subtitulo: this.translateSrv.instant('modal.errorFormDocNameSize.subtitle'),
        subtitulo2: this.translateSrv.instant('modal.errorFormDocNameSize.subtitle2'),
        boton: this.translateSrv.instant('modal.errorFormDocNameSize.button')
      });
      success = false;
    }
    return success;
  }

  /**
  * Metodo que valida que el nombre del documento
  * no contenga caracteres invalidos.
  */
  public checkInvalidCharacterFileName(traceId: string, documento: any, modalTrad: any) {
    let success = true;
    if (this.ficheroSrv.hasCaracteresInvalidos(documento)) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.error, 'Error en carácteres nombre documento', this.sessionSrv.getCurrentUserMask());
      this.modalSrv.mostrarModal(ErrorFormComponent, {
        titulo: this.translateSrv.instant('modal.errorFormDocNameInvalidChar.title'),
        subtitulo: this.translateSrv.instant('modal.errorFormDocNameInvalidChar.subtitle'),
        subtitulo2: this.translateSrv.instant('modal.errorFormDocNameInvalidChar.subtitle2'),
        boton: this.translateSrv.instant('modal.errorFormDocNameInvalidChar.button')
      });
      success = false;
    }
    return success;
  }

  /**
  * Metodo que abre el modal
  * cuando se superan los
  * 10MB
  */
  public checkDocumentSize(traceId: string, documento: any, modalTrad: any) {
    let sumaSizeDoc = 0;
    sumaSizeDoc = this.sumaSizeDocs() + documento.target.files[0].size;
    let success = true;
    if (sumaSizeDoc > 5242880) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.error, 'Error en tamaño documento', this.sessionSrv.getCurrentUserMask());
      this.modalSrv.mostrarModal(ErrorFormComponent, {
        titulo: this.translateSrv.instant(this.errorFormTitle),
        subtitulo: this.translateSrv.instant('modal.errorForm.incorrectDocumentSize'),
        subtitulo2: this.translateSrv.instant('modal.errorForm.incorrectDocumentSizeSubtitle'),
        boton: this.translateSrv.instant(this.altIconoCerrar)
      });
      success = false;
    }
    return success;
  }

  /**Metodo que suma todos
  * los tamaños de los ficheros
  * subidos
  */
  public sumaSizeDocs() {
    let docsOk = this.estadoDocumentos.filter((d: EstadoDocumento) => d.subido);
    docsOk = docsOk.concat(this.estadoSolicitud.filter((d: EstadoDocumento) => d.subido));
    const sum = docsOk.reduce((acc, doc) => {
      return acc + doc.sizeDoc;
    }, 0);
    return sum
  }

  /**
  * Método que lee el objeto
  * del documento subido
  * y guarda su contenido en base64
  * @param documento
  * @param docId
  */
  public async readFile(documento: any, fromSolicitud: boolean, docId?: number, tipoDocumento?: string, nombreTipoDocumento?: string) {
    const reader = new FileReader();
    let fileBase64!: string;
    reader.readAsDataURL(documento.target.files[0]);
    reader.onload = () => {
      fileBase64 = (reader.result as string).split(',')[1];
      this.checkExtension(documento, fileBase64, fromSolicitud, docId, tipoDocumento, nombreTipoDocumento);
    };
  }

  /**
   * Metodo que comprueba si la extension
   * del fichero subido es valida o no
   * @param documento
   * @param contenido
   * @param docId
   */
  public async checkExtension(documento: any, contenido: string, fromSolicitud: boolean, docId?: number, tipoDocumento?: any, nombreTipoDocumento?: any) {
    const estadoDoc = {
      nombreDocumento: documento.target.files[0].name,
      tipoDocumento: tipoDocumento,
      nombreTipoDocumento: nombreTipoDocumento,
      documento: contenido,
      sizeDoc: documento.target.files[0].size,
      subido: (await Promise.all([this.ficheroSrv.checkExtension(documento.target.files[0], this.arrayExtensiones)
      ]))[0]
    };
    if (!fromSolicitud) {
      !isNil(docId) ?
        this.estadoDocumentos.splice(docId, 1, estadoDoc) :
        this.estadoDocumentos.push(estadoDoc);
    } else {
      !isNil(docId) ?
        this.estadoSolicitud.splice(docId, 1, estadoDoc) :
        this.estadoSolicitud.push(estadoDoc);
    }
    this.sessionSrv.showSpinner = false;
  }

  /**
   * Método que valida si el DNI
   * o el NIE son correctos
   * @param control
   * @returns
   */
  public validator(control: string): boolean {
    let result = false;
    if (dniValidador(control) || nieValidator(control)) {
      result = true;
    }
    return result;
  }
  /**
   * Método para habilitar anexar
   * documento
   * @returns true si cumple con la condición,
   * false en caso contrario
   */
  deshabilitarAnexarDocumento() {
    let deshabilitar = false;
    if (this.utilsSrv.validarLengthArray(this.estadoDocumentos)) {
      const tipoDocumento = this.estadoDocumentos[0].tipoDocumento;
      switch (tipoDocumento) {
        case '37': // dni anverso + reverso
        case '254': // permiso de residencia
          deshabilitar = this.utilsSrv.compararValores(this.estadoDocumentos.length, 1);
          break;
        case '248': // certificado nie
        case '38': // pasaporte
        case '35': // dni anverso
        case '36': // dni reverso
        case '249': // id extrajero
        case '259': // visado
          deshabilitar = this.utilsSrv.compararValores(this.estadoDocumentos.length, 2);
          break;
      }
    }
    return deshabilitar;
  }

}

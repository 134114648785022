<!-- Opción 1: icono + label -->
<ng-container *ngIf="label != undefined; else noLabel">
  <button class="iconButton" type="button"  mat-button [class]="cssClass" [class.disabled]="disabledButton" (click)="emitClick()"
    [disabled]="disabledButton">
    <div class="flex row align-center gap10">
      <img class="icon" [src]="srcIcon" alt="icono">
      <span class="label">{{label | translate}}</span>
    </div>
  </button>
</ng-container>
<!-- Opción 2: sólo icono -->
<ng-template #noLabel>
  <button mat-icon-button type="button" class="iconButton noLabel flex align-center justify-center" [class]="cssClass"
    [class.disabled]="disabledButton"
    [disabled]="disabledButton"
    (click)="emitClick()">
    <img class="icon" [src]="srcIcon" alt="icono">
  </button>
</ng-template>

<div mat-dialog-content class="decisionModal">
  <div class="flex end" *ngIf="data.showIconClose">
    <mm-icon-button matDatepickerToggleIcon [srcIcon]="'./assetsArq/img/icons/close_grey.svg'"
      mat-dialog-close></mm-icon-button>
  </div>
  <div class="flex column align-center gap40">
    <div class="flex column align-center gap32">
      <div class="image">
        <img [src]="data.urlImagen" alt="decision modal icono">
      </div>
      <div class=" msg">
        <p class="title">{{data.title | translate}}</p>
        <p class="subtitle">{{data.subtitle1 | translate}}</p>
        <p class="subtitle">{{data.subtitle2 | translate}}</p>
      </div>
    </div>
    <div class="flex row gap24" *ngIf="data.showButtons">
      <mm-button [label]="data.labelButton1" [disabledButton]="data.disableButton1"
        [cssClass]="data.disableButton1 ? 'secundario disabled' : data.cssBoton1"
        (notifyClick)="notificarClick1()"></mm-button>
      <mm-button [label]="data.labelButton2" [disabledButton]="data.disableButton2"
        [cssClass]="data.disableButton2 ? 'primario disabled' : data.cssBoton2"
        (notifyClick)="notificarClick2()"></mm-button>
    </div>
  </div>
</div>
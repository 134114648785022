<div class="input-form">
  <mat-form-field appearance="fill">
    <mat-label class="new-label">
      {{ controlLabel }}
    </mat-label>
    <mat-select [formControl]="control" #singleSelect>
      <mat-option>
        <!-- Aqui hi ha declarat el cercador dintre del buscador -->
        <ngx-mat-select-search [formControl]="controlFilter" ngDefaultControl [placeholderLabel]="controlLabel"
          [noEntriesFoundLabel]="labelNoEntries">
          <mat-icon ngxMatSelectSearchClear>
            <img [src]="'./assetsArq/img/icons/close_popup.svg'" alt="{{ 'modal.errorForm.altIcono' | translate }}">
          </mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <!-- Aqui ens mostra el resultat de la busqueda del cercador i recolleig el valor -->
      <div *ngIf="name=='hosp' || name=='esp'">
        <mat-option>--</mat-option>
      </div>
      <mat-option *ngFor="let resultObj of list" [value]="resultObj.Value">
        {{ resultObj.Nom }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-error *ngIf="control.errors?.['required'] && control.touched">
      {{ "app.fieldRequired" | translate }}
    </mat-error>
  </div>
</div>

import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { DecisionModal } from '../../../core/models/htmlComponents/decisionModal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mm-decision-modal',
  templateUrl: './decision-modal.component.html',
  styleUrls: ['./decision-modal.component.scss']
})
export class DecisionModalComponent {
  /**Eventos que se emiten al componente padre */
  @Output() clickBtn1: EventEmitter<boolean> = new EventEmitter();
  @Output() clickBtn2: EventEmitter<boolean> = new EventEmitter();
  /**
   * Método constructor
   * inyección de los datos a mostrar
   * @param data
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: DecisionModal,
  private dialogRef: MatDialogRef<DecisionModalComponent>) {
   }
  /**
   * Lanza un evento al padre
   * para notificar que se ha clicado
   */
  notificarClick1() {
    this.clickBtn1.emit(true);
    this.dialogRef.close();
  }
  notificarClick2() {
    this.clickBtn2.emit(true);
    this.dialogRef.close();
  }
}

import { Component, inject, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SessionService } from '../../../core/providers/session/session.service';
import { TranslateService } from '@ngx-translate/core';
import { AsistenteOcrService } from '../../../core/providers/ocr/asistenteOcr.service';
import { ValidacionOcrService } from '../../../core/providers/ocr/validacionOcr.service';
import { isNil } from 'lodash';



@Component({
  selector: 'datos-documento',
  templateUrl: './datos-documento.component.html',
  styleUrls: ['./datos-documento.component.scss'],
})
export class DatosDocumentoComponent implements OnInit {
  @Input() formInputs!: any;
  public translate!: TranslateService;
  public asistenteOcrSrv!: AsistenteOcrService;
  public sessionSrv!: SessionService;
  public validacionOcrSrv!: ValidacionOcrService;
  public fechas!: Array<any>;

  constructor() {
    this.translate = inject(TranslateService);
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.sessionSrv = inject(SessionService);
    this.validacionOcrSrv = inject(ValidacionOcrService);
  }

  /**
   * Método para controlar
   * los cambios en el DNI
   */
  async ngOnInit() {
    this.crearFechas();
    this.asistenteOcrSrv.ocrForm.controls['dni'].valueChanges
      .subscribe(newValueDni => {
        if(!isNil(newValueDni)) {
          this.validarDniNie(newValueDni);
        }
      });
  }

  /**
   * Método que crea
   * un array de fechas
   */
  public crearFechas(){
    this.fechas = [
      {
        label: "formInputs.fechaNac",
        name: "fecha"
      },
      {
        label: "formInputs.fechaCad",
        name: "fechacaducidadnif"
      }
    ]
  }

  /**
   * Metodo que valida si el
   * DNI o el NIE son correctos en caso
   * contrario sale un error para advertirnos
   * de que el formato es incorrecto
   */
  public validarDniNie(valueDniNie: string) {
    let controlDniNie = valueDniNie;
    const patronNum = /^\d/;
    const patronChar = /^[XYZxyz]/;
    const longitud = controlDniNie.length;
    const primeraPos = controlDniNie.charAt(0);
    if (longitud > 0 && (patronNum.test(primeraPos) || patronChar.test(primeraPos))) {
      controlDniNie = controlDniNie.padStart(9, '0');
      this.isNieNif(controlDniNie);
    } else {
      this.asistenteOcrSrv.ocrForm.controls['dni']?.setErrors({
        invalido: true,
        required: this.asistenteOcrSrv.ocrForm.controls['dni']?.getError('required')
      });
    }
  }

  /**
   * Metodo que nos confirma si es valido
   * el Nie o el Nif del cliente
   */
  public isNieNif(controlDniNie: string) {
    if (this.validacionOcrSrv.validator(controlDniNie)) {
      this.asistenteOcrSrv.ocrForm.controls['dni']?.setErrors(null);
      this.asistenteOcrSrv.ocrForm.controls['dni'].markAsUntouched();
    } else {
      this.asistenteOcrSrv.ocrForm.controls['dni']?.setErrors({
        invalido: true,
        required: this.asistenteOcrSrv.ocrForm.controls['dni']?.getError('required')
      });
      this.asistenteOcrSrv.ocrForm.controls['dni'].markAllAsTouched();
    }
  }

  /**
   * Acceso al form control dni
   */
  get dni(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('dni');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fechacaducidadnif(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('fechacaducidadnif');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fecha(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('fecha');
  }
}

<div class="titleWebcam">
  <div *ngIf="asistenteOcrSrv.posDNI == 0; else revBlock">
    {{ "webcam.capturaTitleAnv" | translate }}
  </div>
  <ng-template #revBlock>
    {{ "webcam.capturaTitleRev" | translate }}
  </ng-template>
</div>
<div class="flex column captura">
  <video class="ma" [class.show]="!isCaptured" [class.hide-element]="isCaptured" #video id="video" [width]="400"
    [height]="400" autoplay></video>
  <canvas class="ma" [class.show]="isCaptured" [class.hide-element]="!isCaptured" #canvas id="canvas" [width]="400"
    [height]="300"></canvas>
</div>
<div *ngIf="!isCaptured" class="justify-center ma" [ngClass]="sessionSrv.responsive ? 'w-70' : 'w-35'">
  <div *ngIf="asistenteOcrSrv.posDNI == 0; else capturaRevBlock">
    <button class="commonButton primario blue" (click)="capture()" [disabled]="noWebcam">{{
      "webcam.capturaAnv" | translate }}</button>
  </div>
  <ng-template #capturaRevBlock>
    <button class="commonButton primario blue" (click)="capture()">{{ "webcam.capturaRev" |
      translate }}</button>
  </ng-template>
</div>
<div *ngIf="isCaptured" class="flex justify-center column ma" [ngClass]="sessionSrv.responsive  ? 'w-70' : 'w-35'" style="gap:4px">
  <button  class="commonButton primario blue" (click)="guardadoImagen()">{{ "webcam.enviar" |
    translate }}</button>
  <button class="commonButton primario blue" (click)="removeCurrent()">{{ "webcam.repetir" |
    translate }}</button>
</div>

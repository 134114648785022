import { ArqConfig } from '@mutual-lib/frontend/lib/core/models/arqConfig';
import Config from 'src/assets/config/Config.json';

export const arqLibConf: ArqConfig = {
  apiConf: {
    apiKey: Config.apiKey,
    apiUrl: Config.apiUrl,
    cipherKeys: {
      dev: '_,tU{-S&hFn[m7~Qi9r&I!p&y=7X#d;P',
      pre: 'X6p(~c]l2I%mK#9kU}%jX9p&qZ8[m[T[',
      pro: 'K8e{T6}9o=H^]iL5&Ij]y(6rN0mX~]Zd',
      lila: ''
    }
  },
  applicationName: Config.name,
  env: Config.environment,
  identificadorApp: "8B035BD1-CACC-EF11-AF91-005056B3D011",
  urlRedirectSesionExpirada: null,
  cache: {
    apisCache: [],
    tiempoValidezCache: 0,
  },
  minutosEsperaUsuario: null,
  retry: {
    // retryApis: ['formulario'],
    retryApis: [],
    numReintentosPeticion: 0,
    tiempoEntreReintentos: 0
  },
  activarPerfilado: false,
  activarControlSesion: false
};

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, timer } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { LevelType } from '../enum/levelType.enum';
import { ErrorApi } from '../models/errorApi';
import { ErrorService } from '../providers/errors/error.service';
import { LogService } from '../providers/logs/log.service';
import { SessionService } from '../providers/session/session.service';
import { UtilsService } from '../providers/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Si el código de error es alguno de los
   * contemplados en array errorCodes quiere decir que se
   * controlan desde la aplicación o que no son genéricos.
   * El resto de errores harán que salte el pop up
   * genérico donde mostramos el error técnico, ip...
   */
  errorControladoApp!: boolean;
  /**
   * Configuración para reintentos que
   * se carga de la configuración de la
   * libreria
   */
  retryProperties!: any;
  /**
   * Indica si el endpoint fallido pertenece a
   * un api marcada para activar el reintento
   */
  apiToRetry!: boolean;
  /**
  * Errores que se manejan de manera especial
  * desde la aplicación o desde aquí
  */
  errorCodes!: number[];
  /**
   * Nombre de la cabecera del waf
   */
  wafHeader = 'waf-log-id';

  /**
   * Método constructor
   * inyeccion servicios
   * @param errorService servicio para mostrar modal errores
   */
  constructor(private logSrv: LogService,
    private sessionSrv: SessionService,
    private utilSrv: UtilsService,
    private errorSrv: ErrorService) {
    this.retryProperties = this.sessionSrv.getLibConfig().retry;
    this.errorCodes = [400, 401, 402, 403];
  }
  /**
   * Comprueba si el error que ha dado la
   * petición Http es de tipo 500 para
   * volver a lanzarla nuevamente
   * @param error
   * @returns error
   */
  shouldRetry(error: HttpErrorResponse) {
    if (error.status >= 500) {
      return timer(1000);
    }
    throw error;
  }

  /**
   * Método que intercepta los fallos
   * de las llamadas http
   * llama al error service para mostrar el pop up de error
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      retry({
        count: this.retryProperties.retryApis.some((i: string) => req.url.includes(i))
          ? this.retryProperties.numReintentosPeticion : 0,
        delay: this.shouldRetry
      }),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.sessionSrv.wafHeader = !req.url.includes('EnviarLog') ? event.headers.get(this.wafHeader) ?? '' : '';
        }
      }),
      catchError((error: ErrorApi | any) => {
        this.errorControladoApp = this.errorCodes.includes(error?.error?.code ?? error?.status);
        this.apiToRetry = this.retryProperties.retryApis.some((i: string) => req.url.includes(i));
        this.sessionSrv.errorObject = error.error;
        this.sessionSrv.wafHeader = error.headers.get(this.wafHeader);
        if(this.sessionSrv.errorObject?.['Log-Id']) {
          this.sessionSrv.wafHeader = this.sessionSrv.errorObject?.['Log-Id'];
          this.insertWafLog(req);
        }
        /**
         * Se valida que el servicio que ha fallado
         * no sea el de inserción de logs en ELK
         */
        if (!error.url.includes('EnviarLog')) {
          if (!this.errorControladoApp) {
            this.logSrv.insertHttpLogError(req, error);
          }
          if (this.apiToRetry) {
            this.mostrarAccesoDenegado();
          }
        }
        this.sessionSrv.showSpinner = false;
        return throwError(() => new Error(error.error ?? ''));
      })
    );
  }
  
  /**
   * Método que llama al método insertar log en log service
   * se usa desde insertarWafLogPeticionOk y insertarWafLogPeticionKO
   * @param req
   * @param url
   * @param httpEvent
   */
  private insertWafLog(req: HttpRequest<any>) {
    const url = req.url;
    if (!url.includes('EnviarLog') && !url?.includes('Token')) {
      this.logSrv.insertLog(
        req.headers.get('traceid')!,
        url!.split('?')[0],
        'intercept HttpInterceptor',
        LevelType.error,
        'Petición parada por el WAF' + JSON.stringify(this.sessionSrv.errorObject),
        this.sessionSrv.getCurrentUserMask() ?? this.sessionSrv.getCurrentUser()
      );
    }
  }

  /**
   * Si el error es tipo 400 mostramos pop up
   * y redireccionar a la ventana anterior
   */
  mostrarAccesoDenegado() {
    if (this.errorControladoApp) {
      this.errorSrv.showMsgErrorPerfilado();
    } else {
      this.utilSrv.redireccionarUrlAnterior();
    }
  }
}

<!-- Classe que mostra la informacio del formulari -->
<!-- Primer declarem el meta pel noindex -->
<meta name="robots" content="noindex" />
<!-- Mirem si tenim resposta del token per si hem de mostra el formulari -->
<div *ngIf="responseToken" [class.responsive]="sessionSrv.responsive">
  <div class="marginImage">
    <img class="main-image" alt="{{ 'val.titolErrorImg' | translate }}" [src]="userValidator.mainImage" />
  </div>
  <div class="mainContainer form">
    <p class="mainTitle-form">{{ "app.title" | translate }}</p>
    <p class="subtitle">{{ "val.fields" | translate }}</p>
    <div class="fit-form">
      <form [formGroup]="signupForm" #myForm="ngForm" (ngSubmit)="send(myForm)">
        <div class="containerSeccion">
          <p class="titulo">{{'secciones.contacto' | translate}}</p>
          <div class="grid3 gap40">
            <form-input [control]="signupForm.controls['nombre']" [controlLabel]="userValidator.formInputs.nombre"
              [name]="'nombre'">
            </form-input>
            <!-- Aqui declarem el input pel cognom -->
            <form-input [control]="signupForm.controls['primerApellido']" [controlLabel]="userValidator.formInputs.primerApellido"
              [name]="'primerApellido'"></form-input>
            <form-input [control]="signupForm.controls['segundoApellido']" [controlLabel]="userValidator.formInputs.segundoApellido"
              [name]="'segundoApellido'"></form-input>
            <!-- Aqui declarem el selector pel genere -->
            <selector-input [control]="signupForm.controls['genero']" [controlLabel]="userValidator.formInputs.genero"
              [list]="userValidator.selectorGenero"></selector-input>
            <!-- Aqui declarem el input pel telefon movil o el de casa -->
            <form-input [control]=" signupForm.controls['telf']" [controlLabel]="userValidator.formInputs.telf"
              [name]="'telf'">
            </form-input>
            <!-- Aqui declarem el input pel email -->
            <form-input [control]=" signupForm.controls['email']" [controlLabel]="userValidator.formInputs.email"
              [name]="'email'">
            </form-input>
          </div>
        </div>
        <div class="containerSeccion">
          <p class="titulo">{{'secciones.personales' | translate}}</p>
          <div class="grid3 gap40">
            <!-- Aqui declarem el input pel domicili -->
            <form-input [control]=" signupForm.controls['domicilio']"
              [controlLabel]="userValidator.formInputs.domicilio" [name]="'domicilio'"></form-input>
            <!-- Aqui declarem el input per la poblacio -->
            <form-input [control]=" signupForm.controls['poblacion']"
              [controlLabel]="userValidator.formInputs.poblacion" [name]="'poblacion'"></form-input>
            <!-- Aqui declarem el selector per les provincies -->
            <search-input [control]="signupForm.controls['provincia']"
              [controlFilter]="signupForm.controls['provinciaFilter']"
              [controlLabel]="userValidator.formInputs.provincia" [labelNoEntries]="userValidator.search.noProvincia"
              [list]="userValidator.provincias"></search-input>
            <!-- Aqui declarem el input pel codi postal -->
            <form-input [control]=" signupForm.controls['cp']" [controlLabel]="userValidator.formInputs.cp"
              [name]="'cp'">
            </form-input>
          </div>
        </div>
        <div class="containerSeccion">
          <p class="titulo">{{'secciones.profesionales' | translate}}</p>
          <div class="grid3 gap40">
            <!-- Aqui declarem el input pel numero de col·legiat -->
            <form-input [control]=" signupForm.controls['numcol']" [controlLabel]="userValidator.formInputs.numCol"
              [name]="'numcol'">
            </form-input>
            <!-- Aqui declarem el input per la data de col·legiat -->
            <date-input [control]="signupForm.controls['fechacol']"
              [controlLabel]="userValidator.formInputs.fechaCol"></date-input>
            <!-- Aqui declarem el selector i el buscador dels col·legis -->
            <search-input [control]="signupForm.controls['colen']" [controlFilter]="signupForm.controls['colenFilter']"
              [controlLabel]="userValidator.formInputs.colEn" [labelNoEntries]="userValidator.search.noColegio"
              [list]="userValidator.colegis"></search-input>
            <!-- Aqui declarem el selector per la residencia -->
            <selector-input [control]="signupForm.controls['residencia']"
              [controlLabel]="userValidator.formInputs.residencia"
              [list]="userValidator.selectorResidencia"></selector-input>
            <!-- Aqui declarem selector i el buscador dels hospitals -->
            <search-input [control]="signupForm.controls['hospital']"
              [controlFilter]="signupForm.controls['hospitalFilter']" [controlLabel]="userValidator.formInputs.hospital"
              [labelNoEntries]="userValidator.search.noHospital" [list]="userValidator.hospitals"
              [name]="'hosp'"></search-input>
            <!-- Aqui declarem selector i el buscador de les especialitats -->
            <search-input [control]="signupForm.controls['especialidad']"
              [controlFilter]="signupForm.controls['especialidadFilter']"
              [controlLabel]="userValidator.formInputs.especialidad"
              [labelNoEntries]="userValidator.search.noEspecialidad" [list]="userValidator.especialitats"
              [name]="'esp'"></search-input>
            <!-- Aqui declarem selector de com volem rebre la informacio -->
            <selector-input [control]="signupForm.controls['idiomasocio']"
              [controlLabel]="userValidator.formInputs.info" [list]="userValidator.selectorIdioma"></selector-input>
          </div>
        </div>

        <!-- Bloque digitalización, checkbox, imagen y boton siguiente -->
        <div class="flex column" style="gap:15px;">
          <!-- Asistente para la captura del DNI -->
          <div *ngIf="!asistenteOcrSrv.showInputsOCR; else repeatBlock">
            <p class="title-form mb-1per">{{ "asistente.title" | translate}}</p>
            <p class="info-form">{{ "asistente.info" | translate}}</p>
          </div>
          <ng-template #repeatBlock>
            <div matTooltip="{{ 'asistente.tooltip'| translate }}" matTooltipClass="custom-tooltip"
              class="title-form w-fit-content" [matTooltipPosition]="'right'" #tooltip="matTooltip"
              (click)="sessionSrv.responsive ? tooltip.toggle() : ''">
              {{ "asistente.title" | translate}}
              <img [src]="'./assets/img/icons/info-blue.svg'" alt="info-detalle">
            </div>
          </ng-template>

          <!-- Mostrar los datos una vez ejecutado el OCR -->
          <div *ngIf="asistenteOcrSrv.showInputsOCR">
            <!-- Input Documento -->
            <form-input [control]="asistenteOcrSrv.ocrForm.controls['dni']" [controlLabel]="userValidator.formInputs.dni"></form-input>
            <!-- Input Fecha de Nacimiento -->
            <date-input [control]="asistenteOcrSrv.ocrForm.controls['fecha']" [controlLabel]="userValidator.formInputs.fechaNac">
            </date-input>
            <!-- Input fecha expiración -->
            <date-input [control]="asistenteOcrSrv.ocrForm.controls['fechacaducidadnif']"
              [controlLabel]="userValidator.formInputs.fechaCad"></date-input>
          </div>
          <!-- Boton del Asistente -->
          <div class="boxAsistente flex column" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">
            <button type="button" class="fullBlueButton" (click)="openAsistente()">
              {{ (asistenteOcrSrv.showInputsOCR ? "asistente.repetirDigitalizacion" : "asistente.botonAsistente") |
              translate
              }}
            </button>
          </div>
          <!-- Aqui declarem el checkbox per acceptar la politica de privacitat -->
          <div *ngIf="!userValidator.showString">
            <div>
              <mat-checkbox class="checkbox-option new-label" formControlName="condiciones" type="boolean"
                (click)="toggleCond()">
                {{ "app.fieldConditions" | translate }}
              </mat-checkbox>
              <span *ngIf="
                        signupForm.controls['condiciones'].errors?.required &&
                        signupForm.controls['condiciones'].touched
                      ">
                <mat-error>{{ "app.fieldRequired" | translate }}</mat-error>
              </span>
            </div>
          </div>
          <!-- Si es medicorasse mostrara aquest text i aquest PDF -->
          <div *ngIf="userValidator.showString">
            <div class="txtmedicorasse">
              <div>
                <mat-label class="new-label blockMedicorasse"><em>{{ "app.TxtMedicorasse1" | translate
                    }}</em></mat-label>
              </div>
            </div>
            <div>
              <mat-checkbox class="checkbox-option new-label" formControlName="condiciones" type="boolean">
                {{ "app.PrivacyMedicorasse1" | translate }}
                <a href="{{ 'app.PrivacyMedicorasseLink' | translate }}" target="_blank" rel="noopener"><u>{{
                    "app.PrivacyMedicorasse2" | translate }}</u></a>{{ "app.PrivacyMedicorasse3" | translate }}
              </mat-checkbox>
              <span *ngIf="
                  signupForm.controls['condiciones'].errors?.required &&
                  signupForm.controls['condiciones'].touched
                ">
                <mat-error>{{ "app.fieldRequired" | translate }}</mat-error>
              </span>
            </div>
            <br />
          </div>
          <!-- Aqui declarem el checkbox per acceptar la publicitat que ens ofereix l'empresa -->
          <div class="flex row">
            <div class="flex nowrap-row">
              <div class="flex column">
                <div>
                  <mat-checkbox class="checkbox-option new-label" formControlName="publicidad" type="boolean">
                    {{ "app.fieldAdvert" | translate }}
                  </mat-checkbox>
                  <span *ngIf="
                      signupForm.controls['publicidad'].errors?.required &&
                      signupForm.controls['publicidad'].touched
                    ">
                    <mat-error>{{ "app.fieldRequired" | translate }}</mat-error>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Imagen -->
          <div class="position-small">
            <img class="small-image" alt="{{ 'val.titolErrorImg' | translate }}" [src]="userValidator.smallImage" />
          </div>
          <!-- Aqui declarem la process spiner la qual s'activa un cop el formulari esta complet -->
          <div class="flex justify-center mtp-4">
            <button class="next-button"
              [ngClass]="signupForm.invalid || asistenteOcrSrv.ocrForm.invalid ? 'disabledGris' : 'fullBlueButton'"
              [style]="sessionSrv.responsive ? 'width: 240px' : 'width:351px'"
              [disabled]="signupForm.invalid || asistenteOcrSrv.ocrForm.invalid"
              (click)="showSpinner()" type="submit">
              {{ "app.fieldNext" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

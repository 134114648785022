import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../core/providers/session/session.service';

@Component({
  selector: 'sh-error-form',
  templateUrl: './error-form.component.html',
  styleUrls: ['./error-form.component.scss']
})
export class ErrorFormComponent {

  constructor(public sessionSrv: SessionService, public dialogRef: MatDialogRef<ErrorFormComponent>,  @Inject(MAT_DIALOG_DATA) public data: any = {}) { }

 /**
  * Método que cierra 
  * el modal
  */
  closeDialog() {
    this.dialogRef.close(); 
  }
}

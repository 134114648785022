import { Inject, Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { ArqConfig } from '../../models/arqConfig';
import { ErrorApi } from '../../models/errorApi';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio para almacenar y recuperar los
 * datos de la sesión activa
 */
export class SessionService {
  /**
   * dato identificativo del usuario
   * Ej. email, nif/nie, cliente id...
   */
  public currentUser!: string;
  /**
   * dato identificativo del usuario
   * pero enmascarado para los logs
   */
  public currentUserMask!: string;
  /**
   * Información keycloak
   * perfil, roles, token...
   */
  public keycloakInfo!: Keycloak;
  /**
   * Config libreria que se pasa
   * por el método forRoot
   */
  public config!: ArqConfig;
  /**
   * Variable que indica si el login
   * con keycloak y microsoft ha sido
   * correcto
   */
  public loginOk!: boolean;
  /**
   * Indicador para ver si
   * la app está en modo escritorio
   * o mobile/tablet
   */
  public responsive!: boolean;
  /**
   * Indicador para activar/desactivar
   * el spinner de carga
   */
  public showSpinner!: boolean;
  /**
   * Indicador para mostrar/ocultar
   * el contenido de la ventana
   */
  public showContent!: boolean;

  /**
  * Almacena el path de la última url
  * que el usuario ha podido visitar
  */
  public ultimaUrlVisitada: string = '';
  /**
  * Almacena el path de la url actual
  * que el usuario ha podido visitar
  */
  public urlActual: string = '';
  /**
   * Variable que guarda el
   * objeto de error capturado
   * por el interceptor
   */

  public errorObject!: ErrorApi | any;
  /**
   * Headers de la respuesta
   */
  public wafHeader!: string | null;

  /**
   * Array donde se guardan
   * las peticiones http
   */
  public cache: Map<string, any> = new Map();

  /**
   * Variable donde se almacena el
   * idioma actual de la aplicación
   */
  public idiomaActual = 'es';
  /**
   * Método constructor
   * @param libConf configuración de la librería-
   * injection token
   */
  constructor(@Inject('LIB_CONFIG') private libConf: ArqConfig) {
    this.config = this.libConf;
  }
  /**
   * Recupera el usuario actual almacenado
   * @returns string con el usuario
   */
  public getCurrentUser(): string {
    const noUser = 'Usuario no disponible';
    return this.currentUser ?? noUser;
  }
  /**
   * Recupera el usuario actual enmascarado
   * para insertar en los logs
   * @returns string con el usuario
   */
  public getCurrentUserMask(): string {
    return this.currentUserMask ?? '';
  }
  /**
   * Ofusca los usuarios identificados
   * con email
   */
  public ofuscarEmail() {
    const current = this.getCurrentUser();
    if (current.includes('@')) {
      const splitMail = current.split('@');
      const splitDot = splitMail[1].substring(splitMail[1].indexOf('.'), splitMail[1].length);
      this.currentUserMask = splitMail[0].substring(0, 8) + '****@****' + splitDot;
    }

  }
  /**
   * Recupera el usuario actual enmascarado
   * para insertar en los logs
   * @returns string con el usuario
   */
  public getKeycloakInfo(): Keycloak {
    return this.keycloakInfo;
  }
  /**
   * Recupera la configuracion
   * de la libreria
   * @returns objeto tipo ArqConfig
   */
  public getLibConfig(): ArqConfig {
    return this.config;
  }
  /**
   * Devuelve el idioma actual
   * @returns string
   */
  public getIdiomaActual(): string {
    return this.idiomaActual;
  }
  /**
   * Devuelve el locale correspondiente en funcion del idioma actual
   * @returns string
   */
  public getLocale(): string {
    return this.idiomaActual == 'es'? 'es_ES' : '';
  }
}

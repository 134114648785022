<div class="container">
  <!-- Widget o tarjeta para redireccionar l portal de prestaciones -->
  <div class="flex end mb-7" >
    <button class="close" (click)="closeDialog()">
      <!-- icono cerrar -->
    <img [src]="'./assetsArq/img/icons/close_popup.svg'" alt="{{ 'modal.errorForm.altIcono' | translate }}">
  </button>
  </div>
  <div class="main-container" [class.responsive]="sessionSrv.responsive" >
    <!-- icono error -->
      <div class="flex justify-center align-center" >
          <img [src]="data.success ? './assetsArq/img/icons/success_form.svg' : './assetsArq/img/icons/error_form.svg'" class="img" alt="{{ 'modal.errorForm.altIconoCerrar' | translate }}">
        </div>
      <!-- título -->
      <div class="title mb-3" >{{data.titulo}}</div>
      <!-- subtítulo -->
      <div class="flex column justify-center align-center subtitle mb-3">
        <span>{{data.subtitulo}}</span>
        <span >{{data.subtitulo2}}</span>
      </div>
      <!-- Botón para cerrar modal -->
    <div class="flex justify-center align-center" [class.responsive]="sessionSrv.responsive">
      <button class="button" (click)="closeDialog()">{{data.boton}}</button>
    </div>
  </div>
</div>

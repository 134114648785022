export enum LevelType {
  info = "INFO",
  debug = "DEBUG",
  error = "ERROR",
  warn = "WARN",
  fatal = "FATAL",
  trace = 'TRACE'
}
/**
 * Lista de niveles disponibles que se utilizan
 * para catalogar el log que se está insertando en elk
 */

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { LevelType } from '../../enum/levelType.enum';
import { LogService } from '../logs/log.service';
import { SessionService } from '../session/session.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  /**Clase para detectar cambios
   * de tamaño en la ventana */
  public breakpointObserver!: BreakpointObserver;
  /**Servicios */
  private sessionSrv!: SessionService;
  private logSrv!: LogService;
  private utilsSrv!: UtilsService;

  /**
   * Método constructor
   */
  constructor() {
    this.breakpointObserver = inject(BreakpointObserver);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.utilsSrv = inject(UtilsService);
  }

  /**
   * Método que modifica el valor
   * de la variable responsive. En caso
   * de estar en un dispositivo con un width
   * menor de 1279.98px se activará el diseño
   * responsive. Para tamaño superior se activa
   * el diseño de escritorio.
   */

  public checkResponsive(pixelMax?: string) {
    const pixel = pixelMax ?? '1279.98';
    this.breakpointObserver.observe([`(max-width: ${pixel}px)`])
      .subscribe((state: BreakpointState) => {
        this.sessionSrv.responsive = state.matches;
        this.logSrv.insertLog(
          this.utilsSrv.generateTraceId(),
          'checkResponsive',
          'subscribe observer',
          LevelType.info,
          `Modo: ${state.matches ? 'responsive' : 'escritorio'}`,
          this.sessionSrv.getCurrentUserMask()
        );
      });
  }
}
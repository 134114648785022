import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { UtilsService } from '../providers/utils/utils.service';
import { ProfileService } from '../providers/security/profile.service';

@Directive({
  selector: '[visibilityControl]'
})
export class VisibilityControlDirective {


  /**
   * Método constructor
   * @param templateRef
   * @param viewContainer
   * @param sessionSrv
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private profileSrv: ProfileService,
    private utilSrv: UtilsService
  ) { }

  /**
 * Valida si el elemento debe
 * mostrarse en la vista en funcion del array
 * de elementos visibles
 * Pendiente definición nomenclatura componentes para
 * realizar cambios
 */
  @Input() set visibilityControl(id: string | undefined) {
    if (!this.utilSrv.existeElemento(id) || this.profileSrv.elementosVentana.includes(id!)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}

<div [class.fix-width-height]="!asistenteOcrSrv.showWebcam" [class.responsive]="sessionSrv.responsive">
  <div class="flex end">
    <button class="close" (click)="closeDialog()">
      <!-- icono cerrar -->
      <img [src]="'./assetsArq/img/icons/close_popup.svg'" alt="{{ 'modal.errorForm.altIcono' | translate }}">
    </button>
  </div>
  <p class="txtTitle">{{ "modalAsistente.titleModal" | translate }}</p>
  <p class="txtInfo">{{ "modalAsistente.subtitleModal" | translate }}</p>
  
  <button class="form-button fullBlueButton justify-center ma" [ngClass]="sessionSrv.responsive ? 'w-16em' : 'w-45'"
    (click)="resetearDigitalizacion()">{{ "modalAsistente.botonContinuar" | translate }}</button>
  <div class="mt-2">
    <button class="form-button blueButton blueLetter justify-center ma"
      [ngClass]="sessionSrv.responsive ? 'w-16em' : 'w-45'" (click)="closeDialog()">
      {{'modalAsistente.botonCancelar' | translate }}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { ErrorReglasService } from '../providers/errors/error-reglas.service';
import { LogService } from '../providers/logs/log.service';
import { SessionService } from '../providers/session/session.service';
import { LevelType } from '../enum/levelType.enum';

@Injectable()
export class ErrorReglaInterceptor implements HttpInterceptor {
  /**
   * Constructor
   * inyección de servicios
   * @param errorReglasSrv
   * @param logSrv
   * @param sessionSrv
   */
  constructor(private errorReglasSrv: ErrorReglasService, private logSrv: LogService, private sessionSrv: SessionService) { }
  /**
   * Interceptor que captura todas las peticiones http
   * para validar si la respuesta contiene errores de reglas de negocio
   * @param req
   * @param next
   * @returns
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.checkProperty(event.body, req);
        }
      }),
      catchError((error: HttpErrorResponse): Observable<never> => {
        this.checkProperty(error.error, req);
        throw error;
      })
    );
  }
  /**
   * Método que valida si la respuesta contiene
   * errores de reglas de negocio
   * @param body
   * @param req
   */
  checkProperty(body: any, req: HttpRequest<any>): void {
    if (body && body.hasOwnProperty('failedRules')) {
      this.errorReglasSrv.setErrorRegla(body.failedRules);
      this.logSrv.insertLog(
        req.headers.get('traceid')!,
        req.url!.split('?')[0],
        'ErrorReglaInterceptor',
        LevelType.error,
        'Error de reglas de negocio: ' + JSON.stringify(body.failedRules),
        this.sessionSrv.getCurrentUserMask() ?? this.sessionSrv.getCurrentUser()
      );
    }
  }
};

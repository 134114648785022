import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isNil } from 'lodash';
import { Observable, lastValueFrom } from 'rxjs';
import { ApiToken } from '../../models/api-token';
import { SessionService } from '../session/session.service';
import { CookieService } from '../storage/cookie.service';
import { UtilsService } from '../utils/utils.service';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  /**
   * Token actual
   */
  public currentToken!: ApiToken;
  /**
   * Nombre del api en wso2
   */
  public apiName!: string;
  /**Clase http client */
  private http!: HttpClient;
  /**Servicios */
  private sessionSrv!: SessionService;
  private utilsSrv!: UtilsService;
  private encryptionSrv!: EncryptionService;
  private cookieSrv!: CookieService;

  /**
   * Método constructor
   * inyeccion de servicios
   */
  constructor() {
    this.http = inject(HttpClient);
    this.sessionSrv = inject(SessionService);
    this.utilsSrv = inject(UtilsService);
    this.encryptionSrv = inject(EncryptionService);
    this.cookieSrv = inject(CookieService);
    this.apiName = '/GetToken/1.0/Token';
  }

  /**
   * Obtiene y guarda el token
  */
  public async getWSO2Token() {
    this.sessionSrv.showSpinner = true;
    const token = await lastValueFrom(this.generateToken());
    this.setToken(token);
    this.sessionSrv.showSpinner = false;
  }
  /**
   * Obtencion de un nuevo token
   * @returns Observable<ApiToken>
   */
  public generateToken(): Observable<ApiToken> {
    const grantType = 'password';
    const headers = this.createBasicHeader();
    return this.http.post<ApiToken>(
      this.sessionSrv.config.apiConf.apiUrl + this.apiName,
      { grant_type: grantType },
      { headers }
    );
  }
  /**
   * Refresh token
   * @returns Observable<ApiToken>
   */
  public refreshToken(): Observable<ApiToken> {
    const grantType = 'refresh_token';
    const headers = this.createBasicHeader();
    const body = {
      grant_type: grantType,
      refresh_token: this.getToken()!.refresh_token
    };
    return this.http.post<ApiToken>(this.sessionSrv.config.apiConf.apiUrl + this.apiName, body, { headers });
  }

  /**
   * createBasicHeader para Basic Auth
   * @returns HttpHeaders
   */
  private createBasicHeader(): HttpHeaders {
    const cipherKey: any = this.getKey();
    const apiKey = this.encryptionSrv.descifrar(this.sessionSrv.config.apiConf.apiKey, cipherKey);
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + apiKey,
    });
  }
  /**
   * Método para obtener key
   * @returns string
   */
  private getKey() {
    const keys: any = this.sessionSrv.config.apiConf.cipherKeys;
    const env = this.sessionSrv.config.env;
    return keys[env];
  }

  /**
   * Devuelve el token actual
   * @returns token
   */
  public getToken(): ApiToken | undefined {
    return this.currentToken;
  }

  /**
   * Almacena el nuevo token generado
   * @param token nuevo token
   */
  public setToken(token: ApiToken): void {
    this.currentToken = token;
  }

  /**
   * Método que devuelve
   * si el token ha caducado
   */
  isTokenExpired() {
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    return jwtHelper.isTokenExpired(token!.access_token)!;
  }
  /**
   * Se almacena en una cookie
   * la fecha de la ultima peticion para
   * revisar la actividad del usuario
   */
  saveLastHttpReq(bodyReq: any) {
    if (!isNil(bodyReq) && !this.utilsSrv.compararValores(bodyReq.idControl,'refreshToken')) {
      this.cookieSrv.crearCookie('fechaUltimaActividad', JSON.stringify(new Date().getTime()), undefined, 'None');
    }
  }
}

<!-- Componente custom para la cabecera del datepicker de angular material -->
<div class="flex row justify-center align-center">
  <!-- Flecha que va al mes anterior -->
  <button mat-button type="button" (click)="previousClicked('month')">
    <mm-icon-button [srcIcon]="'./assetsArq/img/icons/expand_blue_left.svg'"></mm-icon-button>
  </button>
  <!-- Mes actual mostrado -->
  <span class="month">{{ periodLabel }}</span>
  <!-- Flecha que va al mes siguiente -->
  <button mat-button type="button" (click)="nextClicked('month')">
    <mm-icon-button [srcIcon]="'./assetsArq/img/icons/expand_blue_right.svg'"></mm-icon-button>
  </button>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AsistenteOcrService } from '@mutual-lib/frontend';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  /* Se añaden
  las variables con
  las rutas a los iconos
  de los modales */
  public checkSubida = './assets/img/icons/check_subida.svg';
  public errorDocu = './assets/img/icons/error_form.svg';
  public infoForm = './assets/img/icons/info-blue.svg';
  public error = './assets/img/icons/error.svg';
  imgModalPath: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    public asistenteOcrSrv: AsistenteOcrService,
    @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) { }
  /**
   * Se llama al método
   * que cambia el icono
   * dependiendo del
   * tipo de modal
   */
  ngOnInit(): void {
    this.asistenteOcrSrv.styleCDK();
    this.imgModalPath = this.checkTypeModal(this.data.tipeModal);
  }

  /**
   * Método que sirve para
   * cambiar el
   * icono del modal
   * @param value tipo de modal
   */
  checkTypeModal(value: string): string {
    const tiposModal = new Map([
      ['errorDocu', this.errorDocu],
      ['success', this.checkSubida],
      ['info', this.infoForm],
      ['error', this.error],
    ]);
    return tiposModal.get(value);
  }
  /**
   * Método que
   * sirve para cerrar
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
  }
}

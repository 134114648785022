export enum Regex {
  num = '^[0-9]*$',
  uuid =  '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
  fecha = '^\\d{4}-\\d{2}-\\d{2}$',
  cp = '[0-9]{5}',
  email = '[\\w\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}(\\.[a-zA-Z]{2,6})?', //admite esta combinación email.un-usuario@sub.un-dominio.com.es
  telefono = '[6_7_8_9]{1}[0-9]{8}',
  texto = '^[a-zA-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ \\-]+$',
  contrasenya = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).*.{8,}',
  nombreApellidosCatEsp = `^[ a-zA-ZáéíóúàèòïüÁÉÍÓÚÀÈÒÏÜñÑçÇ'’·\\-]*$`,
  specialChars2 = '^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙºª, \\/\\-]+$'
}
/**
 * Expresiones regulares que son comunes
 */

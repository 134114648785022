<!-- ¿Seguimos consultando relevante para pintarlo? -->
<div *ngIf="config.visible" [ngSwitch]="elementoHTML" class="customField">
  <div class="flex direction-row align-baseline gap8" *ngIf="label || label2">
    <p class="label" *ngIf="label">{{label | translate}}</p>
    <p class="opcional label2" *ngIf="label2">{{label2 | translate}}</p>
    <p class="opcional" *ngIf="!config.requerido && showOptional">{{'opcional' | translate}}</p>
  </div>
  <!-- INICIO DESPLEGABLE -->
  <mat-form-field *ngSwitchCase="'select'" class="input select" [class.readOnly]="!config.modificable">
    <mat-select [openOnFocus]="openOnFocus" (focus)="onFocus()" (click)="onClick($event)" [formControlName]="name"
      [placeholder]="infoPlaceholder | translate" [panelClass]="'selectionPanel'" (closed)="selectOpen = !selectOpen;"
      #select (ngModelChange)="notificarCambio()">
      <ng-container [ngSwitch]="config.configDesplegable  != null ? config.configDesplegable.tipo : 'catalogoValores'">
        <ng-container *ngSwitchCase="'hzObject'">
          <ng-container *ngFor="let opt of config.valoresPosibles">
            <mat-option class="option" [value]="opt[config.configDesplegable!.hzObjectKey!].valor"
              (click)="optionSelected.emit(opt);">
              {{opt[config.configDesplegable!.hzObjectKey!].descripcion}}
            </mat-option>
            <div class="divider"></div>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'basico'">
          <ng-container *ngFor="let opt of config.valoresPosibles">
            <mat-option class="option" [value]="opt" (click)="optionSelected.emit(opt)">{{opt}}
            </mat-option>
            <div class="divider"></div>
          </ng-container>
        </ng-container>
        <!-- Por defecto tipo catalogo de valores -->
        <ng-container *ngSwitchDefault>
          <ng-container *ngFor="let opt of config.valoresPosiblesTraducidos">
            <mat-option class="option" [value]="utilSrv.transformarValorCatalogoValores(opt.catalogoValoresId.valor)"
              (click)="optionSelected.emit(opt)">{{opt.descripcion}}
            </mat-option>
            <div class="divider"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-select>
    <!-- icono custom -->
    <mat-select-trigger matSuffix (click)="config.modificable ? (selectOpen = !selectOpen) : $event.stopPropagation()">
      <mm-icon-button *ngIf="!selectOpen" [srcIcon]="'./assetsArq/img/icons/expand_grey_close.svg'"></mm-icon-button>
      <mm-icon-button *ngIf="selectOpen" [srcIcon]="'./assetsArq/img/icons/expand_grey_open.svg'"></mm-icon-button>
    </mat-select-trigger>
  </mat-form-field>
  <!-- FIN DESPLEGABLE -->
  <!-- INICIO CALENDAR-DATEPICKER -->
  <mat-form-field class="input" [class.readOnly]="!config.modificable" *ngSwitchCase="'calendar'">
    <input matInput [formControlName]="name" [readonly]="!config.modificable"
      [placeholder]="infoPlaceholder | translate" [matDatepicker]="picker" [matDatepickerFilter]="filterCalendar"
      (dateChange)="notificarCambio()">
    <!-- icono estado abierto -->
    <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="config.modificable" (click)="calendarOpen = !calendarOpen">
      <mm-icon-button matDatepickerToggleIcon *ngIf="calendarOpen && !config.modificable"
        [srcIcon]="'./assetsArq/img/icons/close_grey.svg'" (notifyClick)="picker.close()"></mm-icon-button>
    </mat-datepicker-toggle>
    <!-- se usa panelClass para aplicar un estilo propio,
      la cabecera es custom y se usa el componente DatepickerHeader -->
    <mat-datepicker #picker [panelClass]="'datePicker'" (closed)="calendarOpen = !calendarOpen">
    </mat-datepicker>
  </mat-form-field>
  <!-- FIN CALENDAR-DATEPICKER -->
  <!-- Type number -->
  <mat-form-field class="input" [class.readOnly]="!config.modificable" *ngSwitchCase="'number'">
    <input matInput [formControlName]="name" [readonly]="!config.modificable"
      [placeholder]="infoPlaceholder | translate" type="number" pattern="[0-9]+" [min]="config.valorMinimo"
      [max]="config.valorMaximo" [step]="config.incremento" (ngModelChange)="notificarCambio()">
  </mat-form-field>
  <!-- Checkbox -->
  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox class="checkbox" [formControlName]="name" (ngModelChange)="notificarCambio()">{{checkboxLabel |
      translate}}</mat-checkbox>
  </ng-container>
  <!-- INICIO AUTOCOMPLETE -->
  <mat-form-field *ngSwitchCase="'autocomplete'" class="input select" [class.readOnly]="!config.modificable">
    <input type="text" [placeholder]="infoPlaceholder | translate" matInput [formControlName]="name"
      [matAutocomplete]="auto" [matAutocompletePosition]="'below'" [minlength]="config.minLength"
      [maxlength]="config.maxLength"
      (click)="config.modificable ? (selectOpen = !selectOpen) : $event.stopPropagation()"
      (closed)="selectOpen = !selectOpen;" (ngModelChange)="notificarCambio()">
    <mat-autocomplete #auto="matAutocomplete" class="selectionPanel" [displayWith]="displayFnAutocomplete">
      <mat-option *ngFor="let opt of (config.valoresAutocomplete | async) " class="option"
        [value]="opt.valor">{{opt.descripcion}}
      </mat-option>
      <mat-option
        *ngIf="showResults && config.valoresPosiblesTransformed!.length === 0 && form.controls[name]?.value?.length >= config.minLength"
        disabled>{{ 'sinResultados' | translate}}</mat-option>
      <div class="divider"></div>
    </mat-autocomplete>
    <!-- icono custom -->
    <mm-icon-button matSuffix *ngIf="!showResults" [srcIcon]="'./assetsArq/img/icons/search-grey.svg'"></mm-icon-button>
    <mm-icon-button matSuffix *ngIf="showResults" [srcIcon]="'./assetsArq/img/icons/close-black.svg'"
      (notifyClick)="clearValueAutocomplete()"></mm-icon-button>
  </mat-form-field>
  <!-- FIN AUTOCOMPLETE-->
  <!-- DEFAULT - input normal -->
  <mat-form-field class="input" [class.readOnly]="!config.modificable" *ngSwitchDefault>
    <input matInput type="text" [placeholder]="infoPlaceholder | translate" [formControlName]="name"
      [readonly]="!config.modificable" [minlength]="config.minLength" [maxlength]="config.maxLength"
      [pattern]="config.regex" (ngModelChange)="notificarCambio()">
    <mm-icon-button matSuffix *ngIf="isInvalidAndTouched" [srcIcon]="'./assetsArq/img/icons/close_red.svg'"
      (notifyClick)="clearValue()"></mm-icon-button>
  </mat-form-field>
  <!-- ERROR A MOSTRAR -->
  <div *ngIf="isInvalidAndTouched">
    <mat-error class="error" *ngIf="form.controls[name]?.hasError('required')">
      {{ 'campoRequerido' | translate}}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && elementoHTML != 'iban'
      && form.controls[name]?.hasError('minlength')">
      {{ 'campoIncorrectoMinLength' | translate:{value: config.minLength} }}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && elementoHTML != 'iban'
    &&  form.controls[name]?.hasError('min')">
      {{ 'campoIncorrectoMin' | translate:{value: config.valorMinimo} }}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && elementoHTML != 'iban'
      && form.controls[name]?.hasError('maxlength')">
      {{ 'campoIncorrectoMaxLength' | translate:{value: config.maxLength} }}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && elementoHTML != 'iban'
      && form.controls[name]?.hasError('max')">
      {{ 'campoIncorrectoMax' | translate:{value: config.valorMaximo} }}
    </mat-error>
    <mat-error class="error"
      *ngIf="!isValorEmpty && elementoHTML != 'iban' && form.controls[name]?.hasError('pattern')">
      {{ 'formatoIncorrecto' | translate}}
    </mat-error>
    <mat-error class="error"
      *ngIf="!isValorEmpty && elementoHTML != 'iban' && form.controls[name]?.hasError('uuidInvalid')">
      {{ 'autocompleteIncorrecto' | translate}}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && form.controls[name]?.hasError('nifNie')">
      {{ 'nifNieIncorrecto' | translate}}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && elementoHTML == 'iban' && form.controls[name]?.hasError('iban')">
      {{ 'ibanIncorrecto' | translate}}
    </mat-error>
    <mat-error class="error"
      *ngIf="elementoHTML == 'calendar' && (form.controls[name]?.hasError('fechaInvalida') || form.controls[name]?.hasError('matDatepickerFilter') || form.controls[name]?.hasError('matDatepickerParse'))">
      {{ 'fechaIncorrecta' | translate}}
    </mat-error>
    <mat-error class="error" *ngIf="!isValorEmpty && form.controls[name]?.hasError('segundoApellido')">
      {{ 'segundoApellido' | translate}}
    </mat-error>
    <ng-container *ngIf="utilSrv.validarLengthArray(validatorsConfig)">
      <ng-container *ngFor="let i of validatorsConfig">
        <mat-error class="error" *ngIf="!isValorEmpty && form.controls[name]?.hasError(i.nombre)">
          {{ i.txtInfo| translate}}
        </mat-error>
      </ng-container>
    </ng-container>
  </div>
</div>

<pagination-template #p="paginationApi" [id]="config.id ? config.id : ''" [maxSize]="maxSize"
  (pageChange)="config.currentPage = $event; onPageChange($event)">
  <div class="custom-pagination">
    <!-- Flecha página anterior -->
    <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
      <mm-icon-button *ngIf="p.isFirstPage()" [srcIcon]="'./assetsArq/img/icons/expand_grey_open.svg'"></mm-icon-button>
      <mm-icon-button *ngIf="!p.isFirstPage()" [srcIcon]="'./assetsArq/img/icons/expand_blue_right.svg'" (notifyClick)="p.previous()"></mm-icon-button>
    </div>
    <!-- Números de página -->
    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value" class="custom-page">
      <!-- resto paginas -->
      <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        <span>{{ page.label }}</span>
      </a>
      <!-- estilo pagina actual -->
      <div *ngIf="p.getCurrent() === page.value" class="custom-current-page">
        <span>{{ page.label }}</span>
      </div>
    </div>
    <!-- Flecha próxima página -->
    <div class="pagination-next" [class.disabled]="p.isLastPage()" *ngIf="!p.isLastPage()">
      <mm-icon-button *ngIf="!p.isLastPage()" [srcIcon]="'./assetsArq/img/icons/expand_blue_right.svg'" (notifyClick)="p.next()"></mm-icon-button>
    </div>
  </div>

</pagination-template>

import { HttpClient } from '@angular/common/http';
import { EventEmitter, inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import base64url from "base64url";
import { Observable, Subscription } from 'rxjs';
import { LevelType } from '../../../core/enum/levelType.enum';
import { LogService } from '../../../core/providers/logs/log.service';
import { SessionService } from '../../../core/providers/session/session.service';
import { UtilsService } from '../../../core/providers/utils/utils.service';
import { DniBase64 } from '../../models/DniBase64';
import { OCREnvio } from '../../models/OCREnvio';
import { FicheroService } from '../files/fichero.service';
import { ResponsiveService } from '../utils/responsive.service';
import { ValidacionOcrService } from './validacionOcr.service';

@Injectable({
  providedIn: 'root'
})
export class AsistenteOcrService {

  dniBase64!: DniBase64;
  dniNombre!: DniBase64;

  /*Flag que activa la ventana
  de la webcam*/
  showWebcam: boolean = false;

  /*Flag que activa la ventana de seleccionar
  archivo o webcam*/
  showCaptura: boolean = true;
  ocrForm!: UntypedFormGroup;

  /*Flag que marca si
  se muestra los inputs
  en el formulario general*/
  showInputsOCR: boolean = false; 

  /*Flag que marca si
  los campos del OCR son editables
  por algún error en
  el procesamiento de las imágenes*/
  camposEditables!: boolean;
  finAsistente: EventEmitter<any> = new EventEmitter();
  selectorCaraDni: any = [
    { "label": "anv" },
    { "label": "rev" }
  ];
  dniCaraSrv: string = "anv";
  showInputs: boolean = false;

  /**
   * variables para el mat-stepper
   */
  posDNI!: number;

  /*Variable que guarda el error
  en caso de que no haya dispositivo de cámara*/
  hasWebcam!: boolean;
  responsiveCDK!: boolean;

  /**
 * Varible que guarda la subscripcion
 * para despues poder desubscribirse
 */
  ocrSubscription!: Subscription;

  /**
  * Se inyectan los servicios necesarios,
  * se revisa el navegador para cargar la imagen
  * principal en version movil o desktop,
  * se revisa la url para cambiar las imágenes
  * según el idioma y se crea el formulario
  * @param inject
  */
  public formBuilder: UntypedFormBuilder;
  private http: HttpClient;
  private logSrv: LogService;
  private utilsSrv: UtilsService;
  private sessionSrv: SessionService;
  private validacionOcrSrv: ValidacionOcrService;
  private ficheroSrv: FicheroService;
  private responsiveSrv: ResponsiveService;
  public ocrDocument!: any;

  constructor() {
    this.formBuilder = inject(UntypedFormBuilder);
    this.http = inject(HttpClient);
    this.logSrv = inject(LogService);
    this.utilsSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
    this.validacionOcrSrv = inject(ValidacionOcrService);
    this.ficheroSrv = inject(FicheroService);
    this.responsiveSrv = inject(ResponsiveService);
  }


  /**
   * Método que guarda el base64
   * y el nombre de la imagen hecha
   * por webcam
   * @param base64
   * @param dniCara
   */
  public async guardadoDniWebcam(base64: string, dniCara: keyof DniBase64) {
    this.dniBase64[dniCara] = base64;
    this.dniNombre[dniCara] = dniCara + '.png';
    const caraAnv = this.utilsSrv.compararValores(this.dniCaraSrv, "anv");
    const estadoDoc = {
      nombreDocumento: this.dniNombre[dniCara],
      tipoDocumento: caraAnv ? "35" : "36",
      nombreTipoDocumento: caraAnv ? "DNI/NIF/NIE anverso" : "DNI/NIF/NIE reverso",
      documento: base64,
      sizeDoc: this.ficheroSrv.calculateFileSize(base64),
      subido: true
    };
    this.validacionOcrSrv.estadoDocumentos.push(estadoDoc);
  }

  /**
    * Metodo que
    * envía el anverso y
    * reverso del dni en base64 para
    * el procesamiento 
    * del OCR
    * @param lang
    * @param traceid
    */
  callOCREnvio(traceid: string, docAnverso: string, docReverso: string): Observable<OCREnvio> {
    const headers: any = this.utilsSrv.setTraceIdHeader(traceid);
    const body = {
      "docAnverso": docAnverso,
      "docReverso": docReverso
    }
    return this.http.post<OCREnvio>(this.sessionSrv.getLibConfig().apiConf.apiUrl + '/OCR/resultados', body, { headers });
  }

  /**
   * Método para incializar
   * el formulario del OCR
   */
  createOCRForm() {
    this.ocrForm = this.formBuilder.group({
      dni: ['', Validators.required],
      fechacaducidadnif: ['', Validators.required],
      fecha: ['', Validators.required]
    })
  }

  /**
   * Llamada al envio
   * de OCR Y gestión de
   * los datos recibidos
   */
  async pasoInputs() {
    this.sessionSrv.showSpinner = true;
    const anv = base64url.fromBase64(this.dniBase64['anv']?.split("base64,").pop() || '');
    const rev = base64url.fromBase64(this.dniBase64['rev']?.split("base64,").pop() || '');

    const traceId = this.utilsSrv.generateTraceId();
    this.logSrv.insertLog(traceId, 'OCR', 'click', LevelType.info, 'Enviar imagenes OCR', this.sessionSrv.getCurrentUserMask());
    this.callOCREnvio(traceId, anv, rev).subscribe({
      next: (obj: OCREnvio) => {
        this.logSrv.insertLog(traceId, 'OCR', 'enviarOCR', LevelType.info, 'Respuesta recibida OK OCR', this.sessionSrv.getCurrentUserMask());
        this.validarDatosOcr(obj, traceId);

      }, error: () => {
        this.logSrv.insertLog(traceId, 'OCR', 'enviarOCR', LevelType.error, 'Error al procesar imagenes OCR', this.sessionSrv.getCurrentUserMask());
        this.editarInputs();
      }
    });
  }

  /**
   * Método que valida si los datos
   * recibidos del OCR son válidos
   * @param jsonOCR
  */
  validarDatosOcr(jsonOCR: OCREnvio, traceId: string) {

    const valGlobalOK = this.utilsSrv.compararValores(jsonOCR.validacionGlobal, "OK");
    const valNumDocOK = this.utilsSrv.compararValores(jsonOCR.validacionNumDoc, "OK");
    const valFechaNacOK = this.utilsSrv.compararValores(jsonOCR.validacionFechaNac, "OK");
    const valFechaExpOK = this.utilsSrv.compararValores(jsonOCR.validacionFechaExp, "OK");

    if (!valGlobalOK || (!valNumDocOK && !valFechaNacOK && !valFechaExpOK)) {
      this.logSrv.insertLog(traceId, 'OCR', 'click', LevelType.info, 'Datos OCR vacios', this.sessionSrv.getCurrentUserMask());
      this.editarInputs();
    }
    else {
      jsonOCR.numDocumento = valNumDocOK ? jsonOCR.numDocumento : '';
      jsonOCR.fechaNacimiento = valFechaNacOK ? new Date(jsonOCR.fechaNacimiento) : '';
      jsonOCR.fechaExpiracion = valFechaExpOK ? new Date(jsonOCR.fechaExpiracion) : '';
      this.logSrv.insertLog(traceId, 'OCR', 'click', LevelType.info, 'Datos OCR completos', this.sessionSrv.getCurrentUserMask());
      this.setValuesInputs(jsonOCR);
    }
  }

  /**
  * Método que permite
  * resetear los valores
  * del formulario
  * y editar los campos
  * de los inputs
  */
  public editarInputs() {
    this.ocrForm.reset();
    this.resetDNI(true);
  }

  /**
   * Método que setea
   * los valores del formulario
   * con los recibidos del OCR
   * @param results
   */
  setValuesInputs(results: OCREnvio) {
    this.ocrForm.controls['dni'].setValue(results.numDocumento);
    this.ocrForm.controls['fecha'].setValue(results.fechaNacimiento);
    this.ocrForm.controls['fechacaducidadnif'].setValue(results.fechaExpiracion);
    this.resetDNI(false);
  }

  /**
   * Método que nos permite
   * editar los campos del OCR
   * a mano
   * @param isBoolean
   */
  public resetDNI(isBoolean: boolean) {
    this.sessionSrv.showSpinner = false;
    this.camposEditables = isBoolean;
    this.showInputs = true;
    this.ocrSubscription?.unsubscribe();
  }

  /**
   * Ajusta los estilos
   * del la propiedad
   * CDK si esta en responsive
   */
  public styleCDK(): void {
    this.responsiveSrv.checkResponsive('700');
    if( this.sessionSrv.responsive) {
      const cdk = document.getElementsByClassName("cdk-overlay-pane");
      cdk[0].setAttribute('style', 'max-width: 80vw; position: static; overflow-y: scroll;');
    }
  }

  /**
   * Método para parar todos
   * los MediaStreams
   * @param hasVideoDevice
   */
  closeAllMediaStream(hasVideoDevice: boolean) {
    navigator.mediaDevices.getUserMedia({ video: hasVideoDevice }).then((mediaStream) => {
      mediaStream.getTracks()
        .forEach(track => track.stop());
    })
  }

  /**
   * Método que cierra el modal
   * del OCR
   */
  asistenteFinalizado() {
    this.closeAllMediaStream(true);
    this.finAsistente.emit();
  }
}
import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { isNil } from 'lodash';
import { SessionService } from '../providers/session/session.service';
import { TokenService } from '../providers/security/token.service';
import { KeycloakService } from '../providers/security/keycloak.service';
import { UtilsService } from '../providers/utils/utils.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  /**
   * Declaración de variables de servicios
   */
  private tokenSrv!: TokenService;
  private sessionSrv!: SessionService;
  private keycloakSrv!: KeycloakService;
  private utilSrv: UtilsService;

  /**
   * Método constructor
   * inyección de servicios
   */
  constructor() {
    this.tokenSrv = inject(TokenService);
    this.sessionSrv = inject(SessionService);
    this.keycloakSrv = inject(KeycloakService);
    this.utilSrv = inject(UtilsService);
  }

  /**
   * capture all http request
   * Se valida que el token no haya expirado
   * En caso de estar expirado, no se realiza la llamada http
   * para evitar un error
   * @param request HttpRequest<unknown>
   * @param next HttpHandler
   * @returns Observable<HttpEvent<unknown>>
  */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.tokenSrv.getToken();
    const req = request;
    let result: Observable<HttpEvent<unknown>> = next.handle(request);
    if (!isNil(token) && !req.url.includes('Token')) {
      const tokenValido = this.utilSrv.existeElemento(this.sessionSrv.getKeycloakInfo()) ?
        (!this.keycloakSrv.keycloak.isTokenExpired() && !this.tokenSrv.isTokenExpired())
        : !this.tokenSrv.isTokenExpired();
      if (tokenValido) {
        this.tokenSrv.saveLastHttpReq(req.body);
        request = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            locale: this.sessionSrv.getLocale(),
            access_token: token.access_token,
            authorization: `Bearer ${token.access_token}`,
            refresh_token: token.refresh_token,
            user: this.sessionSrv.getCurrentUser(),
            applicationid: this.sessionSrv.getLibConfig().applicationName,
            jwt_token: this.sessionSrv.getKeycloakInfo()?.token! ?? '',
            jwt_refresh_token: this.sessionSrv.getKeycloakInfo()?.refreshToken! ?? '',
            applicationuuid: this.sessionSrv.getLibConfig().identificadorApp
          }
        });
        result = next.handle(request);
      } else {
        result = EMPTY;
        if (this.utilSrv.existeElemento(this.sessionSrv.getKeycloakInfo())) {
          this.keycloakSrv.logout(true);
        }
      }
    }
    return result;
  }

}

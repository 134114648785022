<!-- Bloque de los errores devueltos por la validación -->
<div class="flex column gap8" *ngIf="utilSrv.existeElemento(failedRules)">
  <!-- tipo error -->
  <mm-banner-informativo *ngIf="failedRules?.error?.length! > 0" [type]='typeBanners.error'
    [elementsArray]='errorArray!'></mm-banner-informativo>
    <!-- tipo info -->
  <mm-banner-informativo *ngIf="!onlyErrors && failedRules?.info?.length! > 0" [type]='typeBanners.info'
    [elementsArray]='infoArray!'></mm-banner-informativo>
    <!-- tipo warning -->
  <mm-banner-informativo *ngIf="!onlyErrors && failedRules?.warning?.length! > 0"
    [type]='typeBanners.warning'
    [elementsArray]='warningArray!'></mm-banner-informativo>
    <!-- tipo referral -->
  <mm-banner-informativo *ngIf="!onlyErrors && failedRules?.referral?.length! > 0"
    [type]='typeBanners.referral'
    [elementsArray]='referralArray!'></mm-banner-informativo>
</div>

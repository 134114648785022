import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LevelType } from '../../../core/enum/levelType.enum';
import { LogService } from '../../../core/providers/logs/log.service';
import { SessionService } from '../../../core/providers/session/session.service';
import { UtilsService } from '../../../core/providers/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { DniBase64 } from '../../../core/models/DniBase64';
import { AsistenteOcrService } from '../../../core/providers/ocr/asistenteOcr.service';

@Component({
  selector: 'webcam-desktop',
  templateUrl: './webcam-desktop.component.html',
  styleUrls: ['./webcam-desktop.component.scss']
})
export class WebcamDesktopComponent implements OnInit, OnDestroy {


  public utilSrv!: UtilsService;
  public asistenteOcrSrv!: AsistenteOcrService;
  public sessionSrv: SessionService;
  public logSrv!: LogService;
  public translate: TranslateService;

  @ViewChild("video")
  public video!: ElementRef;

  @ViewChild("canvas")
  public canvas!: ElementRef;
  /*Variable que guarda el base64 de la imagen*/
  captura: string = "";
  /*Variable que controla si
  una imagen ha sido ya capturada*/
  isCaptured!: boolean;
  /*Variable que indica
  si es el anverso o reverso del
  documento */
  dniCara!: string;
  /**
   * Tipo de nivel para los logs
   * del ELK
   */
  levelType!: LevelType;
  /**
   * Mensaje que se pone en los logs
   * del ELK
   */
  msgLog!: string;
  /**
   * Variable booleana
   * para habilitar o deshabilitar
   * el boton de capturar imagen
   * si este no dispone de una webcam
   */
  noWebcam!: boolean;

  constructor() {
    this.utilSrv = inject(UtilsService);
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.translate = inject(TranslateService);
  }

  /**
   * Se detecta si hay algún
   * dispositivo de vídeo disponible
   * y se acciona la cámara
   */
  async ngOnInit() {
    this.dniCara = this.asistenteOcrSrv.dniCaraSrv;
    await this.examinarDispositivos();
    this.sessionSrv.showSpinner = false;
  }

  /**
   * Método que examina
   * si el dispositivo
   * tiene webcam o no
   */
  public async examinarDispositivos() {
    const traceId = this.utilSrv.generateTraceId();
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasVideoDevice = devices.some(device => this.utilSrv.compararValores(device.kind, "videoinput"));
    if (hasVideoDevice) {
      this.noWebcam = false;
      this.levelType = LevelType.info;
      this.msgLog = "Videoinput";
      this.asistenteOcrSrv.closeAllMediaStream(hasVideoDevice);
      this.video.nativeElement.srcObject = await navigator.mediaDevices.getUserMedia({ video: hasVideoDevice });
      this.video.nativeElement.play();
    } else {
      this.noWebcam = true;
      this.levelType = LevelType.error;
      this.msgLog = "NoVideoinput";
    }
    this.logSrv.insertLog(traceId, 'Formulario', 'examinarDispositivos', this.levelType, this.msgLog, this.sessionSrv.getCurrentUserMask());
  }
  /**
* Método para apagar la cámara
*/
  public apagarCamara() {
    if (this.video && this.video.nativeElement) {
      const videoElement = this.video.nativeElement;
      if (videoElement.srcObject) {
        const stream = videoElement.srcObject as MediaStream;
        if (stream && stream.getTracks) {
          stream.getTracks().forEach(track => track.stop());
        }
        videoElement.srcObject = null;
      }
    }
  }
/**
 * Método que se ejecuta al destruirse el componente
 */
  ngOnDestroy() {
    this.apagarCamara();
  }

  /**
   * Método que guarda la imagen en un canvas
   * y la convierte a formato png
   */
  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captura = this.canvas.nativeElement.toDataURL("image/png");
    this.isCaptured = true;
  }

  /**
   * Método que borra el canvas
   * y activa de nuevo el vídeo para
   * poder hacer
   * otra captura
   */
  removeCurrent() {
    const context = this.canvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.isCaptured = false;
  }
  /**
   * Método que pinta
   * la imagen capturada
   * en un canvas para que sea
   * visible para el usuario
   * @param image
   */
  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, 400, 300);
  }
  /**
   * Método que guarda
   * el base64 correspondiente
   * al anverso o reverso del
   * documento en el servicio
   */
  guardadoImagen() {
    this.video.nativeElement.pause();
    this.asistenteOcrSrv.guardadoDniWebcam(this.captura, this.dniCara as keyof DniBase64);
    this.closeModal();
  }

  /**
   * Método que sirve
   * para cerrar el modal
   * de la webcam
   */
  closeModal() {
    this.asistenteOcrSrv.showWebcam = false;
    this.asistenteOcrSrv.showCaptura = true;
    if (!this.utilSrv.compararValores(this.asistenteOcrSrv.dniBase64['rev'], "")) {
      this.asistenteOcrSrv.posDNI = 2;
      this.asistenteOcrSrv.showCaptura = false;
      this.asistenteOcrSrv.pasoInputs();
    }
    else {
      this.asistenteOcrSrv.dniCaraSrv = "rev";
      this.asistenteOcrSrv.posDNI = 1;
    }
  }
}

<div [class.fix-width-height]="!asistenteOcrSrv.showWebcam" [class.responsive]="sessionSrv.responsive">
  <div class="flex end">
    <button class="close" (click)="closeDialog()">
      <!-- icono cerrar -->
      <img [src]="'./assetsArq/img/icons/close_popup.svg'" alt="{{ 'modal.errorForm.altIcono' | translate }}">
    </button>
  </div>
  <div class="flex column justify-center">
    <!-- stepperNumber -->
    <mat-stepper class="customStepper" [selectedIndex]=asistenteOcrSrv.posDNI>
      <!-- Pedir dni ANV/REV -->
      <mat-step *ngFor="let step of steps" [editable]="false">
        <div class="flex column justify-center" *ngIf="!asistenteOcrSrv.showWebcam">
          <img [src]='step.img' alt="{{ step['alt'] | translate }}">
          <p class="txtTitle mb-0">{{ step.titol | translate }} </p>
          <p class="txtInfo">{{ step.info | translate }}</p>
        </div>
        <captura *ngIf="asistenteOcrSrv.showCaptura"></captura>
        <webcam-desktop *ngIf="asistenteOcrSrv.showWebcam"></webcam-desktop>
        <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
      </mat-step>
      <!-- Validar/Llenar datos del dni -->
      <mat-step>
        <div *ngIf="asistenteOcrSrv.showInputs" class="flex justify-center column datosDocumento">
          <datos-documento [formInputs]="formInputs"></datos-documento>
          <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
        </div>
      </mat-step>
      <ng-template #cancelButton>
        <div class="ma flex column justify-center align-center" [ngClass]="sessionSrv.responsive  ? 'w-70' : 'w-35'">
          <button class="commonButton secundario blue mt-2" (click)="closeDialog()">
            {{'asistente.cancelar' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-stepper>
  </div>
</div>
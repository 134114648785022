import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from '../core/core.module';
import { ActionMessageComponent } from './action-message/action-message.component';
import { BannerInformativoComponent } from './banner-informativo/banner-informativo.component';
import { BuscadorComponent } from './buscadores/buscador/buscador.component';
import { ButtonGroupComponent } from './buttons/button-group/button-group.component';
import { ButtonComponent } from './buttons/button/button.component';
import { IconButtonComponent } from './buttons/icon-button/icon-button.component';
import { DirectAccessComponent } from './direct-access/direct-access.component';
import { CustomInputComponent } from './elementos-formularios/custom-input/custom-input.component';
import { DatepickerHeaderComponent } from './elementos-formularios/datepicker-header/datepicker-header.component';
import { InputComponent } from './elementos-formularios/input/input.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { LogoComponent } from './logo/logo.component';
import { MaterialModule } from './material.module';
import { MenuAccionesComponent } from './menus/menu-acciones/menu-acciones.component';
import { MenuSupportComponent } from './menus/menu-support/menu-support.component';
import { MenuComponent } from './menus/menu/menu.component';
import { UserMenuComponent } from './menus/user-menu/user-menu.component';
import { DecisionModalComponent } from './modals/decision-modal/decision-modal.component';
import { ModalAnexarDocumentacionComponent } from './modals/modal-anexar-documentacion/modal-anexar-documentacion.component';
import { ModalErrorPerfiladoComponent } from './modals/modal-error-perfilado/modal-error-perfilado.component';
import { ModalErrorComponent } from './modals/modal-error/modal-error.component';
import { PaginadorComponent } from './paginador/paginador.component';
import { SessionMessageComponent } from './session-message/session-message.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StepperComponent } from './stepper/stepper.component';
import { TablaAccionesComponent } from './tables/tabla-acciones/tabla-acciones.component';
import { TablaSeleccionComponent } from './tables/tabla-seleccion/tabla-seleccion.component';
import { UserImgComponent } from './user-img/user-img.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AngularIbanModule } from 'angular-iban';
import { ResultadosBuscadorComponent } from './buscadores/resultados-buscador/resultados-buscador.component';
import { FiltroBuscadorComponent } from './buscadores/filtro-buscador/filtro-buscador.component';
import { BusquedaComponent } from './buscadores/busqueda/busqueda.component';
import { IconBadgeComponent } from './buttons/icon-badge/icon-badge.component';
import { ErrorReglasComponent } from './error-reglas/error-reglas.component';
import { InputFileComponent } from './elementos-formularios/input-file/input-file.component';
import { CaraDocumentoComponent } from './asistente-documento/cara-documento/cara-documento.component';
import { CapturaComponent } from './asistente-documento/captura/captura.component';
import { DatosDocumentoComponent } from './asistente-documento/datos-documento/datos-documento.component';
import { WebcamDesktopComponent } from './asistente-documento/webcam-desktop/webcam-desktop.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ErrorFormComponent } from './modals/error-form/error-form.component';

/**
 * Etiquetas personalizadas para los días
 * del datepicker
 * */
class CustomDateAdapter extends MomentDateAdapter {
  override getDayOfWeekNames() {
    return ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
  }
}
/**Formato custom de fechas */
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: 'MMMM YYYY',
  }
};

const customComponents = [
  ModalErrorComponent,
  SpinnerComponent,
  ModalErrorPerfiladoComponent,
  ButtonGroupComponent,
  LogoComponent,
  UserImgComponent,
  UserInfoComponent,
  MenuComponent,
  IconButtonComponent,
  UserMenuComponent,
  MenuSupportComponent,
  DirectAccessComponent,
  InputComponent,
  ButtonComponent,
  ActionMessageComponent,
  SessionMessageComponent,
  DecisionModalComponent,
  TablaSeleccionComponent,
  TablaAccionesComponent,
  PaginadorComponent,
  DatepickerHeaderComponent,
  StepperComponent,
  BannerInformativoComponent,
  BuscadorComponent,
  MenuAccionesComponent,
  CustomInputComponent,
  ExpansionPanelComponent,
  SlideToggleComponent,
  ResultadosBuscadorComponent,
  FiltroBuscadorComponent,
  BusquedaComponent,
  IconBadgeComponent,
  ErrorReglasComponent,
  ModalAnexarDocumentacionComponent,
  InputFileComponent,
  CaraDocumentoComponent,
  CapturaComponent,
  DatosDocumentoComponent,
  WebcamDesktopComponent,
  ErrorFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MomentDateModule,
    MatStepperModule
  ],
  declarations: [
    customComponents
  ],
  exports: [
    customComponents,
    CoreModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    AngularIbanModule
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-ES'
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    FormGroupDirective
  ]
})
/**
 * Módulo que incluye componentes comunes
 */
export class SharedModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisibilityControlDirective } from './directives/visibility-control.directive';
import { UserActivityDirective } from './directives/userActivity.directive';
import { FormatToEuroPipe } from './pipes/FormatToEuroPipe.pipe';
import { OpenOnFocusDirective } from './directives/open-on-focus.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';

const pipes = [
  FormatToEuroPipe
];
const directives = [
  UserActivityDirective,
  VisibilityControlDirective,
  OpenOnFocusDirective,
  DragAndDropDirective
];

/**
 * Modulo core donde tenemos los pipe y servicios comunes
 */
@NgModule({
  declarations: [
    directives,
    pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    directives,
    pipes
  ]
})
/**
 * Módulo core que incluye
 * enums, interceptores, servicios...
 */
export class CoreModule { }

import { Component, ElementRef, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { LevelType } from '../../../core/enum/levelType.enum';
import { LogService } from '../../../core/providers/logs/log.service';
import { SessionService } from '../../../core/providers/session/session.service';
import { UtilsService } from '../../../core/providers/utils/utils.service';
import { AsistenteOcrService } from '../../../core/providers/ocr/asistenteOcr.service';


/**
 * Componente que se encarga de mostrar tanto 
 * la cara anversa como la reversa del documento
 */
@Component({
  selector: 'app-cara-documento',
  templateUrl: './cara-documento.component.html',
  styleUrls: ['./cara-documento.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: true }
  }]
})
export class CaraDocumentoComponent implements OnInit {
  /**
   * Servicio de asistente
   */
  public asistenteOcrSrv: AsistenteOcrService;
  /**
   * Servicio de traducción
   */
  public translateSrv: TranslateService;
  /**
   * Servicio de log
   */
  public logSrv: LogService;
  /**
   * Servicio de utilidades
   */
  public utilSrv: UtilsService;
  /**
   * Servicio de sesión
   */
  public sessionSrv: SessionService;
  /**
   * Variable que contiene los inputs del formulario
   */
  formInputs: any;
  /**
   * Variable que contiene la información de la cámara
   */
  stream: any;
  /**
   * Variable que contiene la información del vídeo
   */
  video!: ElementRef;
  /**
   * Variable que contiene los pasos del mat-step
   */
  steps!: any[];
  /**
   * Método constructor
   * @param dialogRef 
   */
  constructor(public dialogRef: MatDialogRef<CaraDocumentoComponent>) {
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.translateSrv = inject(TranslateService);
    this.logSrv = inject(LogService);
    this.utilSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
  }
  /**
   * Método que se ejecuta al inicalizarse el componente
   */
  async ngOnInit() {
    this.inicializar();
    this.comprobarWebcam();
    this.formInputs = await lastValueFrom(this.translateSrv.get('formInputs'));
    this.asistenteOcrSrv.finAsistente.subscribe(() => {
      this.dialogRef.close();
    });
  }

  /**
   * Método donde se inicializan
   * todas las varibles
   */
  private inicializar() {
    this.asistenteOcrSrv.dniCaraSrv = "anv";
    this.asistenteOcrSrv.showCaptura = true;
    this.asistenteOcrSrv.showWebcam = false;
    this.asistenteOcrSrv.showInputs = false;
    this.asistenteOcrSrv.dniBase64 = { "anv": "", "rev": "" };
    this.asistenteOcrSrv.dniNombre = { "anv": "", "rev": "" };
    this.asistenteOcrSrv.posDNI = 0;
    this.asistenteOcrSrv.ocrForm.enable();
    this.asistenteOcrSrv.styleCDK();
    this.inicializarEscaneo();
  }

  /**
   * Método que pregunta al usuario
   * si da permisos para abrir
   * la cámara o no
   */
  public async comprobarWebcam() {
    const traceId = this.utilSrv.generateTraceId();
    this.asistenteOcrSrv.hasWebcam = true;
    const permissionName = "camera" as PermissionName;
    await navigator.permissions.query({ name: permissionName })
      .then(({ state }) => {
        if (this.utilSrv.compararValores(state, "granted")) {
          this.asistenteOcrSrv.hasWebcam = true;
          this.logSrv.insertLog(traceId, 'Formulario', 'comprobarWebcam', LevelType.info, 'Granted', this.sessionSrv.getCurrentUserMask());
        } else if (this.utilSrv.compararValores(state, "denied")) {
          this.asistenteOcrSrv.hasWebcam = false;
          this.logSrv.insertLog(traceId, 'Formulario', 'comprobarWebcam', LevelType.info, 'Denied', this.sessionSrv.getCurrentUserMask());
        }
      });
  }

  /**
   * Método que
   * sirve para cerrar
   * el modal
   */
  closeDialog() {
    this.dialogRef.close();
    this.asistenteOcrSrv.showWebcam = false;
  }

  /**
   * Método que crea un array
   * para los dos primeros steps
   * del mat-step
   */
  private inicializarEscaneo() {
    this.steps = [
      {
        img: "./assetsArq/img/ocr/icono-dni-anverso.svg",
        alt: "asistente.anvIcono",
        titol: "asistente.anvTitle",
        info: "asistente.anvInfo"
      },
      {
        img: "./assetsArq/img/ocr/icono-dni-reverso.svg",
        alt: "asistente.revIcono'",
        titol: "asistente.revTitle",
        info: "asistente.revInfo"
      }
    ]
  }
}

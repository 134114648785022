import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { LevelType } from '../../../core/enum/levelType.enum';
import { CompressB64 } from '../../../core/models/compressB64';
import { DniBase64 } from '../../../core/models/DniBase64';
import { FicheroService } from '../../../core/providers/files/fichero.service';
import { LogService } from '../../../core/providers/logs/log.service';
import { ModalService } from '../../../core/providers/modal/modal.service';
import { AsistenteOcrService } from '../../../core/providers/ocr/asistenteOcr.service';
import { ValidacionOcrService } from '../../../core/providers/ocr/validacionOcr.service';
import { SessionService } from '../../../core/providers/session/session.service';
import { UtilsService } from '../../../core/providers/utils/utils.service';
import { ErrorFormComponent } from '../../modals/error-form/error-form.component';

@Component({
  selector: 'captura',
  templateUrl: './captura.component.html',
  styleUrls: ['./captura.component.scss']
})
export class CapturaComponent implements OnInit {
  public translate: TranslateService;
  public modalSrv: ModalService;
  public asistenteOcrSrv: AsistenteOcrService;
  public ficheroSrv: FicheroService;
  public logSrv: LogService;
  public utilSrv: UtilsService;
  public sessionSrv: SessionService;
  public validacionOcrSrv: ValidacionOcrService;

  public dniCara!: string;
  public modalTrad!: any;

  constructor() {
    this.translate = inject(TranslateService);
    this.modalSrv = inject(ModalService);
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.ficheroSrv = inject(FicheroService);
    this.logSrv = inject(LogService);
    this.utilSrv = inject(UtilsService);
    this.sessionSrv = inject(SessionService);
    this.validacionOcrSrv = inject(ValidacionOcrService);
  }

  async ngOnInit() {
    this.dniCara = this.asistenteOcrSrv.dniCaraSrv;
    this.modalTrad = await lastValueFrom(this.translate.get('modal'));
  }

  /**
   * Método que guarda el valor del base64 de la imagen
   * y revisa que no supere los 5MB,
   * que el nombre no contenga caracteres especiales
   * y que la longitud del nombre sea adecuada
   * @param evento
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   *
   */
  onselectDoc(documento: any) {
    const traceId = this.utilSrv.generateTraceId();
    const isEmptyFileArray = this.utilSrv.validarLengthArray(documento.target.files.length);
    const isValidDocument = this.isValidDocument(documento, traceId);
    if (isEmptyFileArray) {
      this.cleanImage(false, traceId);
    }
    if (!isEmptyFileArray && isValidDocument) {
      this.asistenteOcrSrv.ocrDocument = documento;
      this.readImage(documento, traceId);
    }
  }
  /**
   * Método que validad el tamaño del nombre de un fichero, que no tenga caracteres no validos
   * y el tamaño del documento
   * @param documento tipo any
   * @param traceId tipo string
   * @returns true en caso que se cumpla, false en caso contrario
   */
  private isValidDocument(documento: any, traceId: string): boolean {
    return (
      this.validacionOcrSrv.checkMaxfileName(traceId, documento, this.modalTrad) &&
      this.validacionOcrSrv.checkInvalidCharacterFileName(traceId, documento, this.modalTrad) &&
      this.validacionOcrSrv.checkDocumentSize(traceId, documento, this.modalTrad)
    );
  }

  /**
   * Función que permite
   * leer el objeto que contiene
   * los datos de la imagen
   * @param evento
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   */
  public readImage(evento: HTMLInputElement & { target: HTMLInputElement }, traceId: string) {
    this.asistenteOcrSrv.dniNombre[this.dniCara as keyof DniBase64] = evento.target.files![0].name;
    const reader = new FileReader();
    let fileBase64!: string;
    reader.readAsDataURL(evento.target.files![0]);
    reader.onload = () => {
      fileBase64 = (reader.result as string).split(',')[1];
      this.checkExtension(evento, traceId, fileBase64);
    };

  }

  /**
   * Limpia la variable que
   * guarda la imagen
   * @param variable La variable se corresponde con
   * 'dnianv' o 'dnirev'
   */
  public async checkExtension(evento: HTMLInputElement & { target: HTMLInputElement }, traceId: string, fileBase64: any) {
    const checking = (await Promise.all([this.ficheroSrv.checkExtension(evento.target.files![0], this.validacionOcrSrv.arrayExtensiones)
    ]))[0];
    if (checking) {
      const estadoDoc = {
        nombreDocumento: evento.target.files![0].name,
        tipoDocumento: this.utilSrv.compararValores(this.asistenteOcrSrv.dniCaraSrv, "anv") ? "35" : "36",
        nombreTipoDocumento: this.utilSrv.compararValores(this.asistenteOcrSrv.dniCaraSrv, "anv") ? "DNI/NIF/NIE anverso" : "DNI/NIF/NIE reverso",
        documento: fileBase64,
        sizeDoc: evento.target.files![0].size,
        subido: (await Promise.all([this.ficheroSrv.checkExtension(evento.target.files![0], this.validacionOcrSrv.arrayExtensiones)
        ]))[0]
      };
      this.validacionOcrSrv.estadoDocumentos.push(estadoDoc);
      
      const file = evento.target.files![0];
      const compressOpt: CompressB64 = {
        width: 600,
        type: file.type,
        max: 200,
        min: 20
      };
      const base64Str = await this.ficheroSrv.createBase64(file, compressOpt);
      this.asistenteOcrSrv.dniBase64[this.dniCara as keyof DniBase64] = base64Str;
      this.comprobarCaraDni();
      
    } else {
      this.cleanImage(true, traceId);
    }
  }

  /**
   * Metodo que nos comprueba
   * si la cara reversa del DNI
   * esta con datos
   */
  public comprobarCaraDni() {
    if (!this.utilSrv.compararValores(this.asistenteOcrSrv.dniBase64['rev'], "")) {
      this.asistenteOcrSrv.showCaptura = false;
      this.asistenteOcrSrv.pasoInputs();
    } else {
      this.asistenteOcrSrv.dniCaraSrv = "rev";
      this.dniCara = "rev";
    }
    this.asistenteOcrSrv.posDNI += 1;
  }

  /**
   * Metodo que nos
   * limpia las variables
   * de las imagenes
   * @param variable dniAnv | dniRev
   * @param size si el tamaño del fichero supera los 5 megabytes
   */
  cleanImage(errorExtension: boolean, traceId: string) {
    this.asistenteOcrSrv.dniBase64[this.dniCara as keyof DniBase64] = "";
    this.asistenteOcrSrv.dniNombre[this.dniCara as keyof DniBase64] = "";
    this.asistenteOcrSrv.dniCaraSrv = this.dniCara;
    if (errorExtension) {
      this.logSrv.insertLog(traceId, 'captura', 'mostrarModalError', LevelType.error, 'Error en la extension del documento', this.sessionSrv.getCurrentUserMask());
      this.modalSrv.mostrarModal(ErrorFormComponent, {
        titulo: this.translate.instant('modal.errorFormExtension.title'),
        subtitulo: this.translate.instant('modal.errorFormExtension.subtitle'),
        boton: this.translate.instant('modal.errorFormExtension.button')
      });
    }
  }

  /**
   * Método que cambia las variables
   * del captura a false y
   * webcam a true cuando abrimos la
   * webcam
   */
  abrirWebcam() {
    this.asistenteOcrSrv.showCaptura = false;
    this.sessionSrv.showSpinner = true;
    this.asistenteOcrSrv.showWebcam = true;
  }
}
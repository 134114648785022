<div class="input-form" [class.responsive]="sessionSrv.responsive">
  <mat-form-field appearance="fill">
    <mat-label class="new-label">{{
      controlLabel
      }}</mat-label>
    <input matInput [formControl]="control" type="text" autocomplete="off" />
  </mat-form-field>
  <mat-error *ngIf="
          control.errors?.['required'] &&
          control.touched
        ">
    {{ "app.fieldRequired" | translate }}</mat-error>
  <div *ngIf="name=='email' || name=='telf' || name=='cp' || name=='numcol'; else others">
    <mat-error *ngIf="control.errors?.['pattern'] && control.touched">
      {{'validators.formatoIncorrecto' | translate}}</mat-error>
  </div>
  <ng-template #others>
    <mat-error *ngIf="control.errors?.['pattern'] && control.touched">
      {{'validators.invalidChar' | translate}}</mat-error>
  </ng-template>
  <mat-error *ngIf="control.hasError('segundoApellido')">
    {{ 'validators.segundoApellido' | translate }}
  </mat-error>
</div>
